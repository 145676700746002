import { IMG_TYPE_LIST } from "../../lib/constants/constant";

export const RGS_SET_TYPE = {
  PORT: "P",
  SAIL: "S",
  GENERATED: "G",
};

export const RGS_CLCT_COLS = {
  tab: [
    "data",
    "path",
    "port",
    "berth",
    "vessel",
    "date",
    "img_cnts",
    "rgs_rep",
    "crt_t",
    "clct_cmp_t",
    "module",
  ],
};

export const RGS_DATA_COLS = {
  [RGS_SET_TYPE.PORT]: {
    tab: ["sensor_name", "clct_t", "filename"],
    hide: {
      table: {
        tab: [],
      },
      thumb: {
        tab: ["filename"],
      },
    },
  },
  [RGS_SET_TYPE.SAIL]: {
    tab: ["sensor_name", "clct_t", "filename"],
    hide: {
      table: {
        tab: [],
      },
      thumb: {
        tab: ["filename"],
      },
    },
  },
  [RGS_SET_TYPE.GENERATED]: {
    tab: ["sensor_name", "clct_t", "filename", "gen_seg_gt_ul_t", "gen_det_gt_ul_t"],
    hide: {
      table: {
        tab: [],
      },
      thumb: {
        tab: ["filename"],
      },
    },
  },
};

export const makeImgTypeList = (dataSetType) =>
  IMG_TYPE_LIST.filter((x) =>
    (dataSetType === RGS_SET_TYPE.GENERATED
      ? ["orig_thumb", "gt_seg_thumb", "gt_det_thumb"]
      : ["orig_thumb"]
    ).includes(x.thumb)
  );

export const RGS_SENSOR = {
  [RGS_SET_TYPE.PORT]: [
    { name: "cam", value: "1", ko: "Camera" },
    { name: "rad", value: "2", ko: "Radar" },
  ],
  [RGS_SET_TYPE.SAIL]: [
    { name: "cam", value: "1", ko: "Camera" },
    { name: "rad", value: "2", ko: "Radar" },
  ],
  [RGS_SET_TYPE.GENERATED]: [{ name: "cam", value: "1", ko: "Camera" }],
};
