import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import clsx from "clsx";
import { currentFilterPosState, currentFilterState, currentMenuState } from "../../lib/stores";
import { useSelectTableRows } from "../../lib/hooks";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import {
  CONDITIONS,
  CAM_SEG_CONDITIONS,
  CAM_DET_CONDITIONS,
  LRN_CONDITIONS,
  ORIGS,
  SENSORS,
} from "../../lib/constants/constant";
import { SelectCheckbox, SelectItem } from "../atoms/SelectBox";
import { ImageSprite } from "../atoms/CommonAtoms";
import FilterItem from "./search/FilterItem";

export const RenderHeader = React.memo(
  ({
    columns,
    state,
    contents,
    allChecked,
    setSelectedItems,
    previewQuery,
    filterApi,
    statusCodeData,
    groupColumnData,
    main,
  }) => {
    const { urlParams, queryParams } = useDynamicRouting();
    const urlInfo = { ...urlParams, ...queryParams };
    const { sensor_code, gt_code } = urlInfo;
    const { tab, view, page, sort, sortType, t, ...filterQuery } = queryParams;
    const { menu } = useRecoilValue(currentMenuState);

    const [currentFilter, setcurrentFilter] = useRecoilState(currentFilterState);
    const setcurrentFilterPos = useSetRecoilState(currentFilterPosState);
    const [status, setStatus] = useState(null);
    const [filterValues, setFilterValues] = useState(null);
    const { handleClickHeaderCheckbox } = useSelectTableRows(contents, setSelectedItems, "obj");

    const getFilterList = async (col) => {
      setStatus("loading");

      try {
        const data = await filterApi({ ...urlInfo, col }, filterQuery);

        const newData = data?.values?.map((x) => {
          return [x[0].toString(), x[1]];
        });
        setFilterValues(newData);
        setStatus("hasValue");
      } catch (err) {
        console.log(err?.response);
        setStatus("hasError");
      }
    };

    const handleHeaderClick = (item) => {
      if (queryParams.view === "thumb") {
        const el = document.getElementsByClassName("thumb_head")[0];
        const pos = el.scrollLeft;
        queryParams.view === "thumb" && setcurrentFilterPos(pos);
      }

      if (currentFilter === item.col) {
        setcurrentFilter("");
      } else {
        setcurrentFilter(item.col);
        getFilterList(item.col);
      }
    };

    const renderFilter = (head) => (
      <FilterItem head={head} data={filterValues} status={status} statusCodeData={statusCodeData} />
    );

    const renderColumn = (col, i) => (
      <td align="center" key={i} className={clsx(col.col === currentFilter && "!bg-gray500")}>
        <div
          className={clsx(
            "flex items-center gap-[6px]",
            col.col && col.filter !== false && "cursor-pointer"
          )}
          onClick={() => col.col && col.filter !== false && handleHeaderClick(col)}
        >
          {col.ko}
          {col.col && col.filter !== false ? (
            <ImageSprite boxSize="scale_small" iconType={col.icon ? col.icon : "IC_filter_s"} />
          ) : null}
        </div>
        {currentFilter === col.col && renderFilter(col)}
      </td>
    );

    const determineColumns = (name) => {
      let columns = groupColumnData;
      if (name === "cond_cnts") {
        if (menu.en === "Learning") columns = LRN_CONDITIONS;
        else if (menu.en === "Check") {
          if (sensor_code === "cam" && gt_code === "seg") {
            columns = CAM_SEG_CONDITIONS;
          } else if (sensor_code === "cam" && gt_code === "det") {
            columns = CAM_DET_CONDITIONS;
          } else {
            columns = CONDITIONS;
          }
        } else columns = CONDITIONS;
      } else if (name === "sensor_cnts") {
        columns = SENSORS;
      } else if (name === "img_cnts") {
        columns = ORIGS;
      }
      return columns;
    };

    useEffect(() => {
      setcurrentFilter(null);
      setcurrentFilterPos(null);
    }, [state]);

    return (
      <thead>
        {columns.find(({ type }) => type === "group" || type === "cnts") ? (
          <tr className="sticky top-[-34px] z-10 whitespace-nowrap">
            <td></td>
            <td></td>
            {previewQuery && <td></td>}
            {columns.map((col, idx) =>
              col.type === "group" || col.type === "cnts" ? (
                <td
                  key={idx}
                  align="center"
                  colSpan={determineColumns(col.name).length}
                  className="relative before:absolute before:left-1/2 before:top-0 before:z-10 before:h-full before:w-[calc(100%-16px)] before:translate-x-[-50%] before:border-b before:border-gray600"
                >
                  {col.ko}
                </td>
              ) : (
                <td key={idx}></td>
              )
            )}
            <td className="w-[90%]"></td>
          </tr>
        ) : null}

        <tr
          className={clsx(
            "sticky z-10 whitespace-nowrap",
            columns.find(({ type }) => type === "group" || type === "cnts")
              ? "top-[-1px]"
              : "top-[0px]"
          )}
        >
          {queryParams.view === "thumb" ? (
            <td className={clsx(queryParams.view === "thumb" && "!px-[6px]")}></td>
          ) : (
            <td align="center">번호</td>
          )}
          {!main && (
            <td className="px-[8px] py-[4px]" align="center">
              <SelectItem>
                <SelectCheckbox
                  onChange={(e) => handleClickHeaderCheckbox(e, allChecked)}
                  checked={allChecked}
                  value={allChecked}
                />
              </SelectItem>
            </td>
          )}

          {previewQuery && (
            <td>
              <ImageSprite boxSize="scale_small" iconType="IC_preview_s" />
            </td>
          )}
          {columns.map((col, idx) =>
            col.type === "group" || col.type === "cnts"
              ? determineColumns(col.name).map((subCol, i) => renderColumn(subCol, i))
              : renderColumn(col, idx)
          )}
          <td className="w-[90%]"></td>
        </tr>
      </thead>
    );
  }
);
