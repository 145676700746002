import { useEffect, useState, useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { CommonButton } from "../../components/molecules/Buttons";
import { SelectCheckbox } from "../../components/atoms/SelectBox";
import { setOperations } from "../../lib/utils";
import { useBatchIdProcessor, useModals } from "../../lib/hooks";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import {
  vldClctCmp,
  vldClctPri,
  vldDelCheck,
  vldExtraDwlLink,
  vldGetInfo,
  vldSensorList,
  vldMaskUpdate,
  vldMaskDelete,
  vldSegAuto,
  vldDetAuto,
  vldAutoResultDwlLink,
  vldInf,
  vldInfModel,
  vldMapUl,
  vldOrigAllDwlLink,
  vldOrigDwlLink,
  vldPropUl,
  vldReset,
  vldSet,
  vldSetAll,
} from "./Vld.apis";
import { EmptyModal, SaveModal, ConfirmModal } from "../../components/molecules/CommonModals";
import ModalContainer from "../../components/molecules/ModalContainer";
import {
  SelectBox,
  SelectItem,
  SelectName,
  SelectRadio,
  SelectTitle,
  SelectWrap,
} from "../../components/atoms/SelectBox";
import { vldPropListQuery, vldTagsState, vldMaskFileListQuery } from "./Vld.stores";
import {
  ConfirmButton,
  DelCheckButton,
  DownloadButton,
  DownloadAllButton,
  FloatingInsertButton,
  FloatingButton,
  SelectInsertButton,
  TableButton,
} from "../../components/organisms/actions";
import { ModalBottom } from "../../components/molecules/Modals";
import { RangeNumberInput } from "../../components/atoms/CommonAtoms";

export const VldPri = ({ selectedItems }) => {
  const data = ["0", "1", "2", "3", "4", "5"];

  return (
    <SelectInsertButton
      body={{ clct_ids: selectedItems }}
      count={selectedItems.length}
      api={vldClctPri}
      actionName="우선순위 설정"
      icon="IC_tag_w"
      selectList={data}
      selectName={{ key: "pri", ko: "우선순위" }}
    />
  );
};

export const VldPriReset = ({ selectedItems }) => {
  return (
    <ConfirmButton
      count={selectedItems.length}
      api={vldClctPri}
      hasPath
      body={{ clct_ids: selectedItems, pri: null }}
      actionName="우선순위 리셋"
      icon="IC_reset_w"
      type="secondary"
    />
  );
};

export const VldSetsCmp = ({ selectedItems }) => {
  return (
    <ConfirmButton
      count={selectedItems.length}
      api={vldClctCmp}
      hasPath
      body={{ clct_ids: selectedItems }}
      actionName="선별완료"
      icon="IC_complete_w"
    />
  );
};

export const VldAddTag = ({ selectedItems, tag }) => {
  const [checkedItems, setCheckedItems] = useRecoilState(vldTagsState);

  const updateCheckedItems = () => setCheckedItems(setOperations(checkedItems, selectedItems));

  return (
    <CommonButton
      name={`${
        tag === "val" ? "학습용 태그" : tag === "eval" ? "평가용 태그" : tag
      } (${checkedItems.length}개)`}
      onClick={updateCheckedItems}
      type="unfill"
      icon="IC_tag_b"
    />
  );
};

export const SetVldTag = ({ selectedItems, tag }) => {
  const vldTags = useRecoilValue(vldTagsState);
  const filteredVldTags = vldTags.filter((item) => selectedItems.includes(item));

  const [data, setData] = useState({});

  const filterData = () => {
    const body =
      tag === "eval"
        ? {
            all_ids: selectedItems,
            eval_ids: filteredVldTags,
          }
        : {
            all_ids: selectedItems,
            val_ids: filteredVldTags,
          };
    setData(body);
  };

  useEffect(() => {
    filterData();
  }, [selectedItems, vldTags]);

  return (
    <ConfirmButton
      count={selectedItems.length}
      detail={`(${
        tag === "can" ? "대기" : tag === "eval" ? "평가용" : tag === "val" && "학습용"
      } 데이터 : ${filteredVldTags.length}개)`}
      api={vldSet}
      hasPath
      tag={tag}
      body={data}
      actionName="선별"
      icon="IC_complete_w"
    />
  );
};

export const SetAllVldTag = ({ frames }) => {
  return (
    <ConfirmButton
      count={frames}
      detail="평가 데이터 선정을 건너뛰고 [평가 데이터 선정 탭]의 데이터가 전부 [학습용 데이터 선정 탭]으로 이동합니다."
      api={vldSetAll}
      hasPath
      tag="eval"
      actionName="학습용 데이터 선정 탭으로 이동"
      icon="IC_complete_w"
    />
  );
};

export const VldMaskRgs = ({ set_id }) => {
  const [selectedObj, setSelectedObj] = useState(null);
  const modalRef = useRef(null);

  return (
    <TableButton
      ref={modalRef}
      cols={[
        {
          name: "sensor_name",
          cols: "sensorName",
          ko: "센서명",
        },
        {
          name: "filename",
          cols: "filename",
          ko: "파일명",
        },
        {
          type: "image",
          name: "mask_thumb",
          ko: "마스크 이미지",
        },
        {
          type: "member",
          name: "vld_mask_rep",
          ko: "업로드 담당자",
        },
        {
          name: "vld_mask_ul_t",
          ko: "업로드 시간",
        },
      ]}
      select
      selectedObj={selectedObj}
      setSelectedObj={setSelectedObj}
      listQuery={vldMaskFileListQuery}
      actionName="마스크 파일 업로드"
      listName="마스크 파일"
      icon="IC_upload_w"
      showIndex={false}
    >
      <FloatingInsertButton
        listApi={vldSensorList}
        api={vldMaskUpdate}
        actionName="업로드"
        accept="png"
        icon="IC_upload_w"
        type="modal_btn"
        afterSuccessCallback={() => {
          modalRef.current?.reload();
        }}
      />
      <DeleteMasks
        count={selectedObj?.selectedItems?.length ?? 0}
        detail="선택한 마스크 파일을 삭제합니다."
        api={vldMaskDelete}
        body={{ ids: selectedObj?.selectedItems ?? [] }}
        actionName="마스크 삭제"
        icon="IC_delete_w"
        type="modal_btn"
        afterSuccessCallback={() => {
          modalRef.current?.reload();
        }}
      />
    </TableButton>
  );
};

const DeleteMasks = ({
  count,
  detail,
  api,
  body,
  actionName,
  icon,
  type,
  afterSuccessCallback = () => {},
}) => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const [failType, setFailType] = useState("");
  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  const resetOpen = () => {
    setFailType("");
    setModalStatus(null);
    openModal();
  };

  const handleClick = async () => {
    setModalStatus("loading");

    try {
      await api(urlInfo, body);
      setModalStatus("hasValue");
      afterSuccessCallback();
      closeModal();
    } catch (err) {
      console.log(err);
      let err_detail = err?.response?.data?.detail || "";

      setFailType(err_detail);
      setModalStatus("hasError");
    }
  };

  return (
    <>
      <CommonButton name={actionName} onClick={resetOpen} icon={icon} type={type} />

      {modal &&
        (count === 0 ? (
          <EmptyModal closeModal={closeModal} blackBackground={20} />
        ) : (
          <ConfirmModal
            closeModal={closeModal}
            title={actionName}
            count={count}
            detail={detail}
            modalStatus={modalStatus}
            failType={failType}
            handleClick={handleClick}
            blackBackground={20}
          />
        ))}
    </>
  );
};

const AUTO_STEPS = { ONE: 1, TWO: 2, FOUR: 4, EIGHT: 8, SIXTEEN: 16 };
export const VldSegAuto = ({ set_id, afterActionCallback }) => {
  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);
  const [confidence, setConfidence] = useState(0.5);
  const [step, setStep] = useState(AUTO_STEPS.ONE);
  const [blur, setBlur] = useState(false);
  const [message, setMessage] = useState("");

  const handleClick = async () => {
    if (confidence < 0.5 || confidence > 1.0 || !Object.values(AUTO_STEPS).includes(step)) {
      setMessage(`Confidence 값은 0.5 이상 1.0 이하의 값만 입력이 가능합니다.\nStep 값은 1, 2, 4, 8, 16 값만 선택 가능합니다.
      `);
      return;
    }
    try {
      await vldSegAuto({ set_id }, { conf: confidence, step, blur_check: blur });
      closeModal();
      resetState();
      afterActionCallback();
    } catch (e) {
      setMessage(e.response?.data?.detail ?? "자동 선별 중 오류가 발생했습니다.");
    }
  };

  const resetState = () => {
    setConfidence(0.5);
    setStep(AUTO_STEPS.ONE);
    setMessage("");
  };

  return (
    <>
      <CommonButton name="자동 선별" onClick={openModal} icon="IC_complete_w" />

      {modal ? (
        <ModalContainer
          title="자동 선별"
          modalType="save_modal"
          closeModal={() => {
            closeModal();
            resetState();
          }}
        >
          <div className="mt-[10px]">
            <RangeNumberInput
              label="Confidence"
              value={confidence}
              handleChange={(v) => {
                setConfidence(v);
              }}
              min={0.5}
              max={1.0}
              step={0.01}
            />
            <div className="whtiespace-normal flex h-[48px] items-center px-[16px]">
              <span className="mr-[45px]">Step</span>
              {Object.values(AUTO_STEPS).map((autostep) => {
                return (
                  <SelectItem key={`step-${autostep}`} styleType="list" cn="ml-0">
                    <SelectRadio
                      onChange={(e) => {
                        setStep(Number(e.target.value));
                      }}
                      checked={autostep === step}
                      value={autostep}
                    />
                    <span className="ml-[5px]">{autostep}</span>
                  </SelectItem>
                );
              })}
            </div>
            <div className="whtiespace-normal flex h-[48px] items-center px-[16px]">
              <span className="mr-[60px]">Blur</span>
              <SelectItem>
                <SelectCheckbox
                  onChange={() => setBlur((prev) => !prev)}
                  checked={blur}
                  value={blur}
                />
              </SelectItem>
            </div>
          </div>
          {message && (
            <div className="h-[48px] whitespace-pre-wrap px-[16px] text-red-500">{message}</div>
          )}
          <ModalBottom>
            <CommonButton
              name="확인"
              onClick={() => {
                handleClick();
              }}
              type="modal_btn"
            />
          </ModalBottom>
        </ModalContainer>
      ) : null}
    </>
  );
};

export const VldDetAuto = ({ set_id, afterActionCallback }) => {
  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);
  const [message, setMessage] = useState("자동 추론이 시작되었습니다.");

  const handleClick = async () => {
    try {
      await vldDetAuto({ set_id });
      openModal();
      afterActionCallback();
    } catch (e) {
      setMessage(e.response?.data?.detail ?? "자동 추론 중 오류가 발생했습니다.");
      openModal();
    }
  };

  return (
    <>
      <CommonButton name="자동 추론" onClick={handleClick} icon="IC_complete_w" />

      {modal ? (
        <ModalContainer title="자동 추론" closeModal={closeModal}>
          <ul className="h-[48px] px-[16px]">
            <li>{message}</li>
          </ul>
          <ModalBottom>
            <CommonButton
              name="확인"
              onClick={() => {
                closeModal();
              }}
              type="modal_btn"
            />
          </ModalBottom>
        </ModalContainer>
      ) : null}
    </>
  );
};

export const VldInf = ({ selectedItems, contents }) => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const { process } = useBatchIdProcessor();
  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  const [floatingItemContents, setFloatingItemContents] = useState(null);

  const [modelList, setModelList] = useState([]);
  const [failType, setFailType] = useState("");
  const [value, setValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [required, setRequired] = useState(null);

  const resetOpen = () => {
    // 0. 상태 리셋
    setModalStatus(null);
    setValue(null);
    setOpen(false);
    setRequired(false);

    // 1. 모델 정보 가져오기
    getInfModel();

    // 2. 진행 중인 작업 리스트 생성
    addItem();

    // 3. 모달 열기
    openModal();
  };

  const getInfModel = async () => {
    setModalStatus("loading");

    try {
      const data = await vldInfModel(urlInfo);
      console.log(data);

      setModelList(data.results);
      // latest인 아이템을 default로 세팅
      setValue(data.results.find((item) => item.latest === true)?.model || null);
      setModalStatus(null);
    } catch (err) {
      console.log(err?.response);

      let err_detail = err?.response?.data?.detail || "";

      setModalStatus("hasError");
      setFailType(err_detail);
    }
  };

  const addItem = () => {
    // 선택한 아이디의 파일명을 찾기위해 정보 가져오기
    const files = contents.filter((item) => selectedItems.includes(item.id, 0));
    const len = files.length;

    let uploadArr = [];
    for (let i = 0; i < len; i++) {
      uploadArr = [
        ...uploadArr,
        {
          id: files[i].id,
          filename: files[i].filename,
          floatingStatus: "추론",
        },
      ];
    }

    setFloatingItemContents(uploadArr);
  };

  const checkInput = () => {
    setModalStatus("hasError");

    if (!value) {
      setFailType("필수 정보를 입력해 주세요.");
      setRequired(true);
    }
  };

  const handleClick = async (rowId) => {
    closeModal();

    // 선택한 모델의 아이디롤 찾아서 보내기
    const inf_id = modelList.find((item) => item.model === value).id;

    process({
      rowId,
      resType: "id",
      len: selectedItems.length,
      urlInfo,
      api: vldInf,
      body: { ids: selectedItems, inf_id },
    });
  };

  return (
    <>
      <CommonButton name="모델 선택 및 추론" onClick={resetOpen} icon="IC_stats_w" />

      {modal &&
        (selectedItems.length === 0 ? (
          <EmptyModal closeModal={closeModal} />
        ) : (
          <SaveModal
            title="모델 선택 정보 확인"
            count={selectedItems.length}
            closeModal={closeModal}
            modalStatus={modalStatus}
            handleClick={handleClick}
            value={value}
            checkInput={checkInput}
            panelname="모델 선택 및 추론"
            floatingItemContents={floatingItemContents}
            failType={failType}
          >
            <SelectWrap required={required && !value}>
              <SelectName name="모델" required={required && !value} />
              <SelectTitle onClick={() => setOpen(!open)} name={value} styleType="modal" />
              <SelectBox open={open} styleType="modal">
                {modelList.map((item, i) => (
                  <SelectItem key={i} item={item.model} pagination styleType="list">
                    <SelectRadio
                      onChange={(e) => {
                        setValue(e.target.value);
                        setOpen(false);
                      }}
                      checked={value === item.model}
                      value={item.model}
                    />
                  </SelectItem>
                ))}
              </SelectBox>
            </SelectWrap>
          </SaveModal>
        ))}
    </>
  );
};

export const VldReset = ({ selectedItems }) => {
  return (
    <ConfirmButton
      count={selectedItems.length}
      api={vldReset}
      hasPath
      body={{ ids: selectedItems }}
      actionName="선별 리셋"
      icon="IC_reset_w"
      type="secondary"
    />
  );
};

export const DownloadVldResult = () => {
  const {
    urlParams: { set_id },
  } = useDynamicRouting();

  return (
    <DownloadButton
      api={vldAutoResultDwlLink}
      hasPath
      body={{}}
      dlUrl={`/vld/cam/seg/datas/${set_id}/auto/dwl/`}
      actionName="데이터 분포 다운로드"
      icon="IC_download_w"
    />
  );
};

export const DownloadOrigAll = ({ count }) => {
  const {
    urlParams: { set_id },
  } = useDynamicRouting();

  return (
    <DownloadAllButton
      count={count}
      api={vldOrigAllDwlLink}
      dlUrl={`/vld/rad/seg/datas/${set_id}/orig/dwl/`}
      actionName="전체 원본 데이터 다운로드"
      icon="IC_download_w"
    />
  );
};

export const DownloadOrig = ({ selectedItems }) => {
  const {
    urlParams: { set_id },
  } = useDynamicRouting();

  return (
    <DownloadButton
      count={selectedItems.length}
      api={vldOrigDwlLink}
      hasPath
      body={{ ids: selectedItems }}
      dlUrl={`/vld/rad/seg/datas/${set_id}/orig/dwl/`}
      actionName="원본 데이터 다운로드"
      icon="IC_download_w"
    />
  );
};

export const DownloadGps = ({ count }) => {
  const {
    urlParams: { set_id },
  } = useDynamicRouting();

  return (
    <DownloadButton
      count={count}
      api={vldExtraDwlLink}
      hasPath
      dlUrl={`/vld/rad/seg/datas/${set_id}/extra/dwl/`}
      actionName="GPS/IMU 데이터 다운로드"
      icon="IC_download_w"
    />
  );
};

export const VldMapUpload = () => {
  return (
    <FloatingButton
      api={vldMapUl}
      actionName="참고 데이터 업로드"
      accept="png"
      icon="IC_upload_w"
    />
  );
};

export const VldPropList = () => {
  const modalRef = useRef(null);
  const FULL_COLUMNS = [
    { name: "sensor_name", ko: "센서번호" },
    { name: "filename", ko: "파일명" },
    {
      type: "member",
      name: "uploader",
      ko: "업로드 담당자",
    },
    { name: "prop_ul_t", ko: "업로드 시간" },
  ];

  return (
    <TableButton
      ref={modalRef}
      cols={FULL_COLUMNS}
      listQuery={vldPropListQuery}
      actionName="센서설정 데이터 리스트"
      listName="센서설정 데이터"
      icon="IC_table_b"
      type="unfill"
    >
      <VldPropUpload />
    </TableButton>
  );
};

export const VldPropUpload = () => {
  return (
    <FloatingInsertButton
      listApi={vldGetInfo}
      api={vldPropUl}
      actionName="센서설정 데이터 업로드"
      accept="json"
      icon="IC_upload_w"
      type="modal_btn"
    />
  );
};

export const VldDelCheck = ({ contents, selectedItems }) => {
  return (
    <DelCheckButton
      api={vldDelCheck}
      contents={contents}
      selectedItems={selectedItems}
      type="secondary"
      icon="IC_delete_w"
    />
  );
};
