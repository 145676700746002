import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";
import { login } from "../../lib/apis";
import { checkLogin } from "../../lib/utils";
import LoginForm from "./LoginForm";

const Login = () => {
  const [inputId, setInputId] = useState("");
  const [inputPw, setInputPw] = useState("");
  const [status, setStatus] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [hide, setHide] = useState(false);
  const history = useHistory();

  // 로그인
  const onSubmit = async () => {
    setStatus("loading");
    setErrorMsg("");

    try {
      const data = await login(inputId, inputPw);

      sessionStorage.setItem("access", data.access);
      sessionStorage.setItem("refresh", data.refresh);
      sessionStorage.setItem("expired", data.time);

      setStatus(null);

      history.replace("/main");
    } catch (err) {
      console.log(err.response);
      setStatus("hasError");

      if (err?.response?.status === 401) {
        setErrorMsg("사용자 정보가 일치하지 않습니다.");
      } else if (err?.response?.status === 400 && err?.response?.data?.detail) {
        setErrorMsg(err?.response?.data?.detail);
      } else {
        setErrorMsg("로그인에 실패했습니다.");
      }
    }
  };

  const resetInput = () => {
    setStatus(null);
    setErrorMsg("");
  };

  // 입력값 확인
  const checkInput = (e) => {
    e.preventDefault();
    setStatus(null);

    const close = false;
    if (close) {
      setStatus("hasError");
      setErrorMsg("서비스 점검중입니다.");
      return;
    }

    if (inputId === "") {
      setStatus("hasErrorId");
      setErrorMsg("아이디를 입력해 주세요.");
      return;
    } else if (inputPw === "") {
      setStatus("hasErrorPw");
      setErrorMsg("비밀번호를 입력해 주세요.");
      return;
    }
    onSubmit();
  };

  // 로그인 상태라면 메인으로 보내기
  useEffect(() => {
    if (checkLogin()) return (window.location.href = "/main");
  }, []);

  useHotkeys("shift+n", () => {
    console.log("calm down...");
    setHide(!hide);
  });

  return (
    <LoginForm
      hide={hide}
      status={status}
      errorMsg={errorMsg}
      inputId={inputId}
      inputPw={inputPw}
      setInputId={setInputId}
      setInputPw={setInputPw}
      checkInput={checkInput}
      resetInput={resetInput}
    />
  );
};

export default Login;
