import { useRecoilValueLoadable } from "recoil";
import { Empty, Loader, LoadFail } from "../../components/atoms/Status";
import { mainListQuery } from "./Main.stores";
import { MAIN_LIST } from "./Main.constants";
import RecentList from "./RecentList";

const MainPage = () => {
  const { state, contents } = useRecoilValueLoadable(mainListQuery);

  const findList = () =>
    MAIN_LIST.filter((x) =>
      contents.find((item) => item.datas.length > 0 && item.name === x.name)
    ).map((x) => {
      return {
        ...x,
        data: contents.find((item) => item.name === x.name).datas,
      };
    });

  const mainContents = state === "hasValue" && contents.length > 0 ? findList() : [];

  return (
    <div className="flex h-full w-full flex-row flex-wrap content-start items-start gap-[20px] bg-gray100">
      {state === "loading" ? (
        <Loader />
      ) : state === "hasError" ? (
        <LoadFail failType="데이터를 불러오는데 실패했습니다." />
      ) : mainContents.length > 0 ? (
        mainContents.map((item, index) => (
          <RecentList key={index} data={item} state={state} contents={item.data} />
        ))
      ) : (
        <Empty />
      )}
      <div className="mt-[-20px] h-[20px] w-full"></div>
    </div>
  );
};

export default MainPage;
