import { selector } from "recoil";
import { reloadTriggerState } from "../../lib/stores";
import { mainList } from "./Main.apis";

export const mainListQuery = selector({
  key: "mainListQuery",
  get: async ({ get }) => {
    get(reloadTriggerState);

    const res = await mainList();

    if (res.error) {
      throw res.error;
    }
    return res;
  },
});
