import { useEffect, useState, useRef, useCallback } from "react";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useHotkeys } from "react-hotkeys-hook";
import clsx from "clsx";
import {
  previewCurrentState,
  previewInfoState,
  previewListState,
  imgTypeDataState,
  previewState,
} from "../../lib/stores";
import { useModals } from "../../lib/hooks";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { Loader, LoadFail } from "../atoms/Status";
import { EmptyModal } from "../molecules/CommonModals";
import { ModalTop } from "../molecules/Modals";
import { CondTag, DrawVldTag } from "./CommonTags";
import { vldTagsState } from "../../pages/validation/Vld.stores";
import { SelectItem, SelectRadio } from "../atoms/SelectBox";
import { MiddlePoint, StepIndicator } from "../atoms/CommonAtoms";

const DrawPreview = () => {
  const {
    history,
    urlParams,
    queryParams: { tab },
  } = useDynamicRouting();
  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  const [vldTags, setVldTags] = useRecoilState(vldTagsState); // 선별 태그 정보

  const imgTypeData = useRecoilValue(imgTypeDataState); // 이미지 타입 옵션 리스트
  const previewList = useRecoilValue(previewListState); // 전체 리스트
  const setPreview = useSetRecoilState(previewState); // 미리보기 출력 여부

  const [previewInfo, setPreviewInfo] = useRecoilState(previewInfoState); // 미리보기 고정정보
  const [previewCurrent, setPreviewCurrent] = useRecoilState(previewCurrentState); // 미리보기 현재정보

  const { state, contents } = useRecoilValueLoadable(
    previewInfo?.previewQuery({
      ...urlParams,
      tab: tab,
      id: previewList[previewCurrent.index],
      img_type: previewCurrent?.currentImgType?.pre,
    })
  );

  const previewInit = useRef(false); // 미리보기 창 생성 여부

  const [previewImg, setPreviewImg] = useState({});
  const [previewAdjustment, setPreviewAdjustment] = useState({
    brightness: 0,
    contrast: 0,
    saturate: 0,
  });
  const imageDimensions = useRef({
    width: 0,
    height: 0,
  });
  const currentImageState = useRef({
    scale: 1,
    positionX: 0,
    positionY: 0,
  });
  const [currentImage, setCurrentImage] = useState({ src: "" });

  const [showFunctions, setShowFunctions] = useState(false);
  const [showControlPanel, setShowControlPanel] = useState(true);

  const imageWrapperRef = useRef(null);

  const toggle = () => {
    const id = previewList[previewCurrent.index];

    if (vldTags.includes(id)) {
      setVldTags(vldTags.filter((x) => x !== id));
    } else {
      setVldTags([...vldTags, id]);
    }
  };

  const handleInputChange = (e, name) => {
    const value = e.target.value;
    setPreviewAdjustment({ ...previewAdjustment, [name]: value });
  };

  const closePreview = () => {
    setPreview(false);
    setPreviewInfo(null);
    setPreviewImg({});
    setPreviewAdjustment({
      contrast: 0,
      brightness: 0,
      saturate: 0,
    });
  };

  const resetModal = () => {
    setModalStatus(null);
    closeModal();
  };

  const updateIdx = (index) => {
    setPreviewCurrent({
      ...previewCurrent,
      index,
    });
  };

  const handleArrClick = (arr) => {
    const currentIdx = Number(previewCurrent.index);
    const lastIdx = previewList.length - 1;

    switch (arr) {
      case "prev":
        if (currentIdx <= 0) {
          setModalStatus("first");
          openModal();
        } else {
          updateIdx(currentIdx - 1);
        }
        return;
      case "next":
        if (currentIdx >= lastIdx) {
          setModalStatus("last");
          openModal();
        } else {
          updateIdx(currentIdx + 1);
        }
        return;
      default:
        return;
    }
  };

  const handleKeyboard = (e) => {
    // 이미지 조정 후 키보드 사용시 input range 가 조절되기 때문에 따로 onKeyDown 처리해줌
    if (e.key === "ArrowLeft") handleArrClick("prev");
    if (e.key === "ArrowRight") handleArrClick("next");
    if (e.key === "e" && previewInfo.tag === "eval") toggle();
    if (e.key === "v" && previewInfo.tag === "val") toggle();
    if (e.key === "Escape") {
      closePreview();
      resetModal();
    }
  };

  const handleFitToScreen = (useAnimation = true) => {
    if (
      imageWrapperRef.current &&
      imageDimensions.current.width > 0 &&
      imageDimensions.current.height > 0
    ) {
      const { width, height } = imageDimensions.current;
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      const widthRatio = screenWidth / width;
      const heightRatio = screenHeight / height;

      const scale = Math.min(widthRatio, heightRatio);
      imageWrapperRef.current.setTransform(
        (screenWidth - width * scale) / 2,
        (screenHeight - height * scale) / 2,
        scale,
        useAnimation ? undefined : 0
      );
    }
  };

  const handleOriginalSize = () => {
    const { width, height } = imageDimensions.current;
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    imageWrapperRef.current.setTransform((screenWidth - width) / 2, (screenHeight - height) / 2, 1);
  };

  useEffect(() => {
    const handleResize = () => {
      if (imageWrapperRef.current) {
        imageWrapperRef.current.centerView();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (previewImg.img) {
      const img = new Image();
      img.src = `data:image/webp;base64,${previewImg.img}`;
      img.onload = () => {
        if (!previewInit.current) {
          imageDimensions.current = { width: img.width, height: img.height };
          handleFitToScreen(false); // 이미지 로드 완료 후 호출
          previewInit.current = true;
        } else {
          const scale =
            currentImageState.current.scale * (imageDimensions.current.width / img.width);
          imageWrapperRef.current.setTransform(
            currentImageState.current.positionX,
            currentImageState.current.positionY,
            scale,
            0
          );
          imageDimensions.current = { width: img.width, height: img.height };
        }
        setCurrentImage(img);
      };
    }
  }, [previewImg]);

  useEffect(() => {
    // 브라우저 뒤로가기를 감지하여 미리보기 끔
    let unlisten = history.listen((location) => {
      if (history.action === "POP") closePreview();
    });

    return () => unlisten();
  }, [history]);

  useEffect(() => {
    // next 이미지 응답을 기다리는 동안 prev 이미지를 출력하고
    // next 이미지 응답이 오면 그때 next 이미지로 state 변경
    if (state === "hasValue")
      setPreviewImg({
        index: previewCurrent.index + 1,
        img: contents.img,
        filename: contents.filename,
        difficulty: contents.difficulty,
        display_name: contents.worker?.display_name,
        lon_lat: contents.lon_lat,
        rework_cnt: contents.rework_cnt,
        conds: contents.conds,
        transferred: contents.transferred,
      });
  }, [contents]);

  useEffect(() => {
    // 이미지 줌 스크롤
    if (document.getElementById("zoom_img")) {
      const imgElem = document.getElementById("zoom_img").parentNode.parentNode;
      imgElem.style.overflow = "scroll";
      imgElem.style.paddingTop = "2px";
    }
  }, [previewImg]);

  useHotkeys(["left", "right", "esc", "v", "e"], (e) => {
    switch (e.code) {
      case "ArrowLeft":
        handleArrClick("prev");
        return;
      case "ArrowRight":
        handleArrClick("next");
        return;
      case "Escape":
        closePreview();
        resetModal();
        return;
      case "KeyV":
        previewInfo.tag === "val" && toggle();
        return;
      case "KeyE":
        previewInfo.tag === "eval" && toggle();
        return;
      default:
        return;
    }
  });

  return (
    <>
      <div className="fixed inset-0 z-[80] bg-black/80">
        <div>
          <div
            className={"absolute top-0 z-10 flex h-[40px] w-full justify-center"}
            onMouseEnter={() => setShowFunctions(true)}
            onMouseLeave={() => setShowFunctions(false)}
          >
            <div
              className={clsx(
                "absolute z-10 flex rounded-b-xl bg-black/40",
                "transition duration-300 ease-in-out",
                {
                  "translate-y-0": showFunctions,
                  "-translate-y-full": !showFunctions,
                }
              )}
            >
              <div className="relative mx-1">
                <button
                  className="h-[28px] w-[80px] rounded text-[12px] leading-[24px] text-white focus:outline-none"
                  onClick={handleFitToScreen}
                >
                  맞춤
                </button>
              </div>
              <div className="absolute right-1/2 top-1/2 h-4 w-px -translate-y-1/2 transform bg-white"></div>
              <div className="relative mx-1">
                <button
                  className="h-[28px] w-[80px] rounded text-[12px] leading-[24px] text-white focus:outline-none"
                  onClick={handleOriginalSize}
                >
                  100%
                </button>
              </div>
            </div>
          </div>
          <div
            className={clsx(
              "preview_close",
              "fixed right-[20px] top-[20px] z-10 h-[40px] w-[40px] cursor-pointer",
              "before:absolute before:left-[20px] before:h-[40px] before:w-[2px] before:bg-white",
              "after:absolute after:left-[20px] after:h-[40px] after:w-[2px] after:bg-white"
            )}
            onClick={closePreview}
          ></div>
          {state === "loading" && <Loader styleType="preview" />}
          {state === "hasError" && (
            <div className="h-screen w-screen">
              <LoadFail failType="이미지를 불러올 수 없습니다." />
            </div>
          )}
          {(state === "hasValue" || state === "loading") && (
            <>
              <div className="relative">
                <div
                  // 우클릭 방지
                  onContextMenu={(e) => e.preventDefault()}
                >
                  {previewImg.transferred ? (
                    // 이관된 데이터 일 때
                    state !== "loading" && (
                      <div className="h-screen w-screen">
                        <LoadFail failType="이관된 데이터 입니다." />
                      </div>
                    )
                  ) : // 이관된 데이터가 아닐 때
                  previewImg.img ? (
                    // 서버에 이미지가 있을 때
                    <TransformWrapper
                      ref={imageWrapperRef}
                      minScale="0.2"
                      onTransformed={(ref) => {
                        currentImageState.current = {
                          ...currentImageState.current,
                          scale: ref.state.scale,
                          positionX: ref.state.positionX,
                          positionY: ref.state.positionY,
                        };
                      }}
                    >
                      <TransformComponent>
                        <img
                          id="zoom_img"
                          src={currentImage.src}
                          style={{
                            imageRendering: "pixelated",
                            filter: `contrast(${+previewAdjustment.contrast + 100}%) brightness(${
                              +previewAdjustment.brightness + 100
                            }%) saturate(${+previewAdjustment.saturate + 100}%)`,
                          }}
                          alt="preview"
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  ) : (
                    // 서버에 이미지가 없을 때
                    state !== "loading" && (
                      <div className="h-screen w-screen">
                        <LoadFail failType="이미지가 없습니다." />
                      </div>
                    )
                  )}
                </div>
              </div>
              {!previewInfo.qna && (
                <>
                  <div
                    className={clsx(
                      "preview_prev",
                      "fixed top-[48%] z-10 m-[16px] inline-block cursor-pointer border-b-2 border-r-2 border-white p-[14px]"
                    )}
                    onClick={() => handleArrClick("prev")}
                  />
                  <div
                    className={clsx(
                      "preview_next",
                      "fixed top-[48%] z-10 m-[16px] inline-block cursor-pointer border-b-2 border-r-2 border-white p-[14px]"
                    )}
                    onClick={() => handleArrClick("next")}
                  />
                  <div className="fixed bottom-[20px] mx-auto flex h-[306px] w-[330px] text-white">
                    <div
                      className="hover:bg-gray z-20 flex h-full w-[35px] cursor-pointer items-center hover:opacity-50"
                      onClick={() => {
                        setShowControlPanel((show) => !show);
                      }}
                    >
                      <div
                        className={clsx(
                          "relative left-[15px] inline-block h-[20px] w-[20px] border-b-2 border-r-2 border-white",
                          {
                            "rotate-[135deg] transform": showControlPanel,
                            "-rotate-45 transform": !showControlPanel,
                            "left-[20px]": showControlPanel,
                            "right-[20px]": !showControlPanel,
                          }
                        )}
                      />
                    </div>
                    <div
                      className={`fixed bottom-[20px] left-0 z-10 ${
                        showControlPanel ? "translate-x-0" : "-translate-x-full"
                      } transition duration-300 ease-in-out`}
                    >
                      <div className="pl-[35px]">
                        <ModalTop>
                          <div className="flex items-center text-[18px] font-bold text-white">
                            {previewImg.rework_cnt > 0 && (
                              <StepIndicator
                                step={previewImg.rework_cnt}
                                cn={"mr-[10px] text-[14px] border border-solid border-white"}
                              />
                            )}
                            <span className="inline-block">
                              {previewImg.index}. {previewImg.filename}
                            </span>
                            {/* flex items-center justify-center h-[30px] px-[8px] border rounded-[20px] border-gray400 text-gray700 */}
                            {previewImg.difficulty && renderTag(previewImg.difficulty)}
                            {previewImg.display_name && renderTag(previewImg.display_name)}
                          </div>
                        </ModalTop>

                        {/* 메타 데이터 */}
                        {previewImg.conds && (
                          <ul className="px-[16px] pb-[24px]">
                            <li className="flex h-[30px] items-center gap-[8px]">
                              <MiddlePoint white />
                              <div>수집환경 : </div>
                              <CondTag conds={previewImg.conds} />
                            </li>
                          </ul>
                        )}

                        {/* 이미지 타입 선택 */}
                        <div className="flex gap-[16px] px-[16px] pb-[24px]">
                          {imgTypeData?.map((x, i) => (
                            <SelectItem key={i}>
                              <SelectRadio
                                onChange={(e) =>
                                  setPreviewCurrent({
                                    ...previewCurrent,
                                    currentImgType: JSON.parse(e.target.value),
                                  })
                                }
                                checked={previewCurrent.currentImgType.pre === x.pre}
                                value={JSON.stringify(x)}
                                styleType="white"
                              />
                              <div
                                className={clsx(
                                  "pl-[8px]",
                                  "whitespace-nowrap",
                                  previewCurrent.currentImgType.pre === x.pre && "text-white"
                                )}
                              >
                                {x.ko}
                              </div>
                            </SelectItem>
                          ))}
                        </div>

                        {/* 학습 데이터 선별 - 선별 태그 */}
                        {(previewInfo.tag === "eval" || previewInfo.tag === "val") && (
                          <div className="flex flex-col gap-[4px] px-[16px] pb-[24px]">
                            <DrawVldTag
                              id={previewList[previewCurrent.index]}
                              tag={previewInfo.tag}
                            />
                          </div>
                        )}

                        {/* 이미지 조정 */}
                        {Object.entries(ADJUSTMENT_LIST).map(([key, value], i) => (
                          <div className="whtiespace-normal h-[48px] w-[330px] px-[16px]" key={i}>
                            <span>{value.ko}</span>
                            <span>: {previewAdjustment[key]}</span>
                            <input
                              className="h-[3px] w-full cursor-pointer bg-gray800 outline-0"
                              type="range"
                              min={value.min}
                              max={value.max}
                              step="1"
                              name={key}
                              value={previewAdjustment[key]}
                              onChange={(e) => handleInputChange(e, key)}
                              onDoubleClick={(e) => {
                                e.stopPropagation();
                                setPreviewAdjustment({
                                  ...previewAdjustment,
                                  [key]: 0,
                                });
                              }}
                              onKeyDown={handleKeyboard}
                            />
                          </div>
                        ))}
                        {previewImg.lon_lat && previewImg.lon_lat.every((coord) => coord) && (
                          <div className="whtiespace-normal w-[330px] px-[16px]">
                            <a
                              className="inline-block text-[13px] hover:bg-gray-600"
                              target="_blank"
                              href={`https://earth.google.com/web/@${previewImg.lon_lat[1]},${previewImg.lon_lat[0]},-0.8a,3300d,35y,0h,0t,0r/data=OgMKATA`}
                              rel="noreferrer"
                            >
                              Pos : {previewImg.lon_lat[1]}, {previewImg.lon_lat[0]}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      {modal && (
        <EmptyModal
          closeModal={resetModal}
          detail={modalStatus === "first" ? "첫번째 이미지입니다." : "마지막 이미지입니다."}
        />
      )}
    </>
  );
};

const ADJUSTMENT_LIST = {
  contrast: {
    ko: "대비",
    en: "contrast",
    min: -100,
    max: 100,
  },
  brightness: {
    ko: "명도",
    en: "brightness",
    min: -100,
    max: 100,
  },
  saturate: {
    ko: "채도",
    en: "saturate",
    min: -100,
    max: 100,
  },
};

const renderTag = (text) => {
  return (
    <span className="ml-[10px] inline-block h-[30px] rounded-[20px] border border-gray800 px-[7px] py-[5px] align-middle text-[12px] font-normal text-gray300">
      {text}
    </span>
  );
};

export default DrawPreview;
