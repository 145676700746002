const MENU_LIST = [
  {
    category: "Main",
    icon: "IC_home_s",
    menu_list: [
      {
        permission: "all",
        en: "Dashboard",
        ko: "나의 대시보드",
        path: "/main",
      },
    ],
  },
  {
    category: "Collection",
    icon: "IC_data_s",
    menu_list: [
      {
        permission: "clct_extr",
        en: "Extraction",
        ko: "데이터 추출",
        path: "/extraction",
        query: {
          clct: "?tab=1&page=1&sort=crtT&sortType=desc",
          data: {
            tab: "1",
            page: "1",
            sort: "filename",
            sortType: "asc",
          },
        },
      },
      {
        permission: "clct_rgs",
        en: "Registration",
        ko: "데이터 등록",
        path: "/registration",
        query: {
          clct: "?tab=1&page=1&sort=crtT&sortType=desc",
          data: {
            tab: "1",
            view: "table",
            page: "1",
            sort: "filename",
            sortType: "asc",
          },
        },
      },
    ],
  },
  {
    category: "Camera",
    icon: "IC_camera_s",
    menu_list: [
      {
        permission: "cam_cond",
        en: "Condition",
        ko: "수집환경 입력",
        path: "/condition/camera",
        query: {
          clct: "?tab=2&page=1&sort=clctCmpT&sortType=desc",
          data: {
            tab: "2",
            view: "table",
            page: "1",
            sort: "filename",
            sortType: "asc",
          },
        },
      },
      {
        permission: "clct_rgs",
        en: "Delete History",
        ko: "데이터 삭제 내역",
        path: "/history/camera",
        query: {
          clct: "?page=1&sort=date&sortType=desc",
          data: {
            view: "table",
            page: "1",
            sort: "filename",
            sortType: "asc",
          },
        },
      },
    ],
  },
  {
    category: "Camera Segmentation",
    icon: "IC_camera_s",
    menu_list: [
      {
        permission: "cam_seg_vld",
        en: "Validation",
        ko: "학습 데이터 선별",
        path: "/validation/camera/segmentation",
        query: {
          clct: "?tab=2&page=1&sort=clctCmpT&sortType=desc",
          data: {
            tab: "1",
            view: "thumb",
            page: "1",
            sort: "filename",
            sortType: "asc",
          },
        },
      },
      {
        permission: "cam_seg_work",
        en: "Work",
        ko: "데이터 작업",
        path: "/work/camera/segmentation",
        query: {
          clct: "?tab=1&page=1&sort=date&sortType=desc",
          data: {
            tab: "1",
            view: "table",
            page: "1",
            sort: "filename",
            sortType: "asc",
          },
        },
      },
      {
        permission: "cam_seg_check",
        en: "Check",
        ko: "데이터 검수",
        path: "/check/camera/segmentation",
        query: {
          clct: "?tab=1&page=1&sort=camSegVldCmpT&sortType=desc",
          data: {
            tab: "1",
            view: "table",
            page: "1",
            sort: "filename",
            sortType: "asc",
          },
        },
      },
      {
        permission: "cam_seg_lrn",
        en: "Learning",
        ko: "학습 데이터 관리",
        path: "/learning/camera/segmentation",
        query: {
          clct: "?tab=1&page=1&sort=latestCheckDate&sortType=desc",
          data: {
            tab: "1",
            view: "table",
            page: "1",
            sort: "filename",
            sortType: "asc",
          },
        },
      },
    ],
  },
  {
    category: "Camera Detection",
    icon: "IC_camera_s",
    menu_list: [
      {
        permission: "cam_det_vld",
        en: "Validation",
        ko: "학습 데이터 선별",
        path: "/validation/camera/detection",
        query: {
          clct: "?tab=2&page=1&sort=clctCmpT&sortType=desc",
          data: {
            tab: "1",
            view: "thumb",
            page: "1",
            sort: "filename",
            sortType: "asc",
          },
        },
      },
      {
        permission: "cam_det_work",
        en: "Work",
        ko: "데이터 작업",
        path: "/work/camera/detection",
        query: {
          clct: "?tab=1&page=1&sort=date&sortType=desc",
          data: {
            tab: "1",
            view: "table",
            page: "1",
            sort: "filename",
            sortType: "asc",
          },
        },
      },
      {
        permission: "cam_det_check",
        en: "Check",
        ko: "데이터 검수",
        path: "/check/camera/detection",
        query: {
          clct: "?tab=1&page=1&sort=detVldCmpT&sortType=desc",
          data: {
            tab: "1",
            view: "table",
            page: "1",
            sort: "filename",
            sortType: "asc",
          },
        },
      },
      {
        permission: "cam_det_lrn",
        en: "Learning",
        ko: "학습 데이터 관리",
        path: "/learning/camera/detection",
        query: {
          clct: "?tab=1&page=1&sort=latestCheckDate&sortType=desc",
          data: {
            tab: "1",
            view: "table",
            page: "1",
            sort: "filename",
            sortType: "asc",
          },
        },
      },
    ],
  },
  {
    category: "Radar",
    icon: "IC_radar_s",
    menu_list: [
      {
        permission: "clct_rgs",
        en: "Delete History",
        ko: "데이터 삭제 내역",
        path: "/history/radar",
        query: {
          clct: "?page=1&sort=date&sortType=desc",
          data: {
            view: "table",
            page: "1",
            sort: "filename",
            sortType: "asc",
          },
        },
      },
    ],
  },
  {
    category: "Radar Segmentation",
    icon: "IC_radar_s",
    menu_list: [
      {
        permission: "rad_seg_vld",
        en: "Validation",
        ko: "학습 데이터 선별",
        path: "/validation/radar/segmentation",
        query: {
          clct: "?tab=2&page=1&sort=clctCmpT&sortType=desc",
          data: {
            tab: "1",
            view: "thumb",
            page: "1",
            sort: "filename",
            sortType: "asc",
          },
        },
      },
      {
        permission: "rad_seg_work",
        en: "Work",
        ko: "데이터 작업",
        path: "/work/radar/segmentation",
        query: {
          clct: "?tab=1&page=1&sort=date&sortType=desc",
          data: {
            tab: "1",
            view: "thumb",
            page: "1",
            sort: "filename",
            sortType: "asc",
          },
        },
      },
      {
        permission: "rad_seg_check",
        en: "Check",
        ko: "데이터 검수",
        path: "/check/radar/segmentation",
        query: {
          clct: "?tab=1&page=1&sort=radSegVldCmpT&sortType=desc",
          data: {
            tab: "1",
            view: "thumb",
            page: "1",
            sort: "filename",
            sortType: "asc",
          },
        },
      },
      {
        permission: "rad_seg_lrn",
        en: "Learning",
        ko: "학습 데이터 관리",
        path: "/learning/radar/segmentation",
        query: {
          clct: "?tab=1&page=1&sort=latestCheckDate&sortType=desc",
          data: {
            tab: "1",
            view: "thumb",
            page: "1",
            sort: "filename",
            sortType: "asc",
          },
        },
      },
    ],
  },
  {
    category: "Analysis",
    icon: "IC_stats_s",
    menu_list: [
      {
        permission: "clct_anlyz",
        en: "Statistics",
        ko: "통계 대시보드",
        path: "/statistics",
        query: { clct: "?tab=1" },
      },
    ],
  },
];

export default MENU_LIST;
