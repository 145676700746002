import { useRef, useState } from "react";
import { TextField } from "@mui/material";
import clsx from "clsx";
import { useModals, useReload, useFileUploader, useBatchIdProcessor } from "../../lib/hooks";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { REF_VIDEO_COL } from "../../lib/constants/constant";
import { checkFile, getKrTime } from "../../lib/utils";
import {
  SelectBox,
  SelectCheckbox,
  SelectItem,
  SelectName,
  SelectRadio,
  SelectTitle,
  SelectWrap,
} from "../../components/atoms/SelectBox";
import {
  AcceptModal,
  ConfirmModal,
  EmptyModal,
  SaveModal,
} from "../../components/molecules/CommonModals";
import { CommonButton } from "../../components/molecules/Buttons";
import {
  AcceptButton,
  ConfirmButton,
  DownloadButton,
  DownloadAllButton,
  FloatingButton,
  FloatingSelectButton,
  QnaButton,
  SelectInsertButton,
  TableButton,
  WorkSetNameButton,
  DelCheckButton,
} from "../../components/organisms/actions";
import { checkOrigVideoListQuery, checkWorkSetListQuery } from "./Check.stores";
import {
  checkCancel,
  checkGtPsdDwlLink,
  checkGuideUl,
  checkPass,
  checkReturn,
  checkRework,
  checkQnaAnswer,
  checkGuideDelete,
  checkReset,
  checkMaskCreate,
  checkOrigDwlLink,
  checkClctGuideUl,
  checkClctGuideDelete,
  checkCmp,
  checkWorkSetList,
  checkDistResetWorkSet,
  checkGetInfo,
  checkModifyCheckerReq,
  checkModifyChecker,
  checkModifyCheckerAll,
  checkWorkStartReset,
  checkClctGuideDwlLink,
  checkGuideDwlLink,
  checkOrigAllDwlLink,
  checkGtPsdAllDwlLink,
  checkModifyWorkSet,
  checkGtReUl,
  checkPay,
  checkCancelDirect,
  checkOrigVideoGetInfo,
  checkMakeOrigVideo,
  checkOrigVideoDwlLink,
  checkCreateWorkSet,
  checkWorkSet,
  checkDelWorkSet,
  checkInfo,
  checkAdd,
  checkRemove,
  checkDelCheck,
  checkDistReset,
  checkDistWorkSetList,
  checkInfoValues,
  checkBlendAllDwlLink,
  checkBlendDwlLink,
  checkLabelAllDwlLink,
  checkLabelDwlLink,
  checkGtJsonAllDwlLink,
  checkGtJsonDwlLink,
  checkInspect,
} from "./Check.apis";

export const CheckCmp = ({ selectedItems }) => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const [failType, setFailType] = useState("");
  const { listReload } = useReload();

  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  const handleClick = async () => {
    setModalStatus("loading");

    try {
      const data = await checkCmp(urlInfo, { ids: selectedItems });

      console.log(data);
      closeModal();
      setModalStatus(null);
      urlInfo.gt_code === "seg" && createMask();
      listReload();
    } catch (err) {
      let err_detail = err?.response?.data?.detail || "";

      console.log(err_detail);
      setModalStatus("hasError");
      setFailType(err_detail);
    }
  };

  const createMask = async () => {
    try {
      const data = await checkMaskCreate(urlInfo, {
        ids: selectedItems,
      });

      console.log(data);
    } catch (err) {
      console.log(err?.response);
    }
  };

  return (
    <>
      <CommonButton name="학습반영" onClick={openModal} icon="IC_complete_w" />

      {modal &&
        (selectedItems.length === 0 ? (
          <EmptyModal closeModal={closeModal} />
        ) : (
          <ConfirmModal
            closeModal={closeModal}
            title="학습반영 정보 확인"
            count={selectedItems.length}
            modalStatus={modalStatus}
            failType={failType}
            handleClick={handleClick}
          />
        ))}
    </>
  );
};

export const CheckPay = ({ selectedItems }) => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  const [date, setDate] = useState(getKrTime());
  const { listReload } = useReload();
  const [failType, setFailType] = useState("");
  const [required, setRequired] = useState(false);

  const resetOpen = () => {
    setDate(getKrTime());
    setFailType(null);
    setRequired(false);
    openModal();
  };

  const checkInput = () => {
    setModalStatus("hasError");

    if (!date) {
      setFailType("필수 정보를 입력해 주세요.");
      setRequired(true);
    } else {
      handleClick();
    }
  };

  const handleClick = async () => {
    setModalStatus("loading");

    const body = {
      ids: selectedItems,
      payment_date: date,
    };

    try {
      const data = await checkPay(urlInfo, body);

      console.log(data);
      closeModal();
      listReload();
    } catch (err) {
      let err_detail = err?.response?.data?.detail || "";

      setFailType(err_detail);
      setModalStatus("hasError");
    }
  };

  return (
    <>
      <CommonButton name="대금지급날짜 입력" onClick={resetOpen} icon="IC_complete_w" />

      {modal &&
        (selectedItems.length === 0 ? (
          <EmptyModal closeModal={closeModal} />
        ) : (
          <SaveModal
            closeModal={closeModal}
            title="대금지급날짜 입력 정보 확인"
            count={selectedItems.length}
            modalStatus={modalStatus}
            handleClick={checkInput}
            failType={failType}
          >
            <SelectWrap required={required && !date}>
              <SelectName name="대금지급날짜" />
              <TextField
                type="date"
                size="small"
                sx={{ width: 160 }}
                onChange={(e) => setDate(e.target.value)}
                inputProps={{ min: "2000-01-01", max: "2100-12-31" }}
                defaultValue={date}
              />
            </SelectWrap>
          </SaveModal>
        ))}
    </>
  );
};

export const DownloadOrigAll = ({ count }) => {
  const {
    urlParams: { sensor_code, gt_code, set_id },
  } = useDynamicRouting();

  return (
    <DownloadAllButton
      count={count}
      api={checkOrigAllDwlLink}
      dlUrl={`/check/${sensor_code}/${gt_code}/datas/${set_id}/orig/dwl/`}
      actionName="전체 원본 데이터 다운로드"
      icon="IC_download_w"
    />
  );
};

export const DownloadOrig = ({ selectedItems }) => {
  const {
    urlParams: { sensor_code, gt_code, set_id },
  } = useDynamicRouting();

  return (
    <DownloadButton
      count={selectedItems.length}
      api={checkOrigDwlLink}
      hasPath
      body={{ ids: selectedItems }}
      dlUrl={`/check/${sensor_code}/${gt_code}/datas/${set_id}/orig/dwl/`}
      actionName="원본 데이터 다운로드"
      icon="IC_download_w"
    />
  );
};

export const CheckCancelDirect = ({ selectedItems }) => {
  const data = ["데이터 손상", "계약 중단", "작업 포기", "기타"];

  return (
    <SelectInsertButton
      body={{ ids: selectedItems }}
      count={selectedItems.length}
      api={checkCancelDirect}
      actionName="작업회수"
      icon="IC_remove_w"
      type="secondary"
      selectList={data}
      selectName={{ key: "cncl_cuz", ko: "작업회수 요청 사유" }}
    />
  );
};

export const CheckCreateWorkSet = () => {
  return <WorkSetNameButton api={checkCreateWorkSet} title="생성" icon="IC_add_w" />;
};

export const CheckModifyWorkSet = ({ selectedItems, tableContents }) => {
  return (
    <WorkSetNameButton
      api={checkModifyWorkSet}
      title="수정"
      icon="IC_reset_w"
      selectedItems={selectedItems}
      tableContents={tableContents}
    />
  );
};

export const Inspect = ({ contents, selectedItems }) => {
  return (
    <FloatingSelectButton
      ids={selectedItems}
      count={selectedItems.length}
      contents={contents}
      api={checkInspect}
      actionName="에러검사"
      icon="IC_complete_w"
    />
  );
};

export const CheckWorkSet = ({ selectedItems }) => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);
  const { modalReload } = useReload();

  const [date, setDate] = useState(getKrTime());
  const [failType, setFailType] = useState("");
  const [required, setRequired] = useState(false);

  const resetOpen = () => {
    setDate(getKrTime());
    setFailType(null);
    setRequired(false);
    openModal();
  };

  const checkInput = () => {
    setModalStatus("hasError");

    if (!date) {
      setFailType("필수 정보를 입력해 주세요.");
      setRequired(true);
    } else {
      handleClick();
    }
  };

  const handleClick = async () => {
    setModalStatus("loading");

    try {
      const data = await checkWorkSet(urlInfo, {
        workset_ids: selectedItems,
        dist_date: date,
      });

      console.log(data);
      modalReload();
      closeModal();
    } catch (err) {
      let err_detail = err?.response?.data?.detail || "";

      setFailType(err_detail);
      setModalStatus("hasError");
    }
  };

  return (
    <>
      <CommonButton name="배분" icon="IC_complete_w" type="modal_btn" onClick={resetOpen} />

      {modal &&
        (selectedItems.length === 0 ? (
          <EmptyModal closeModal={closeModal} blackBackground={20} />
        ) : (
          <SaveModal
            closeModal={closeModal}
            title="배분 정보 확인"
            count={selectedItems.length}
            modalStatus={modalStatus}
            handleClick={checkInput}
            failType={failType}
            blackBackground={20}
          >
            <SelectWrap required={required && !date}>
              <SelectName name="배분날짜" />
              <TextField
                type="date"
                size="small"
                sx={{ width: 160 }}
                onChange={(e) => setDate(e.target.value)}
                inputProps={{ min: "2000-01-01", max: "2100-12-31" }}
                defaultValue={date}
              />
            </SelectWrap>
          </SaveModal>
        ))}
    </>
  );
};

export const CheckResetWorkSet = ({ selectedItems }) => {
  return (
    <ConfirmButton
      count={selectedItems.length}
      hasPath
      api={checkDistResetWorkSet}
      body={{ workset_ids: selectedItems }}
      actionName="회수"
      icon="IC_remove_w"
      type="secondary_modal_btn"
      blackBackground={20}
      tableModal
    />
  );
};

export const CheckDelWorkSet = ({ selectedItems }) => {
  return (
    <ConfirmButton
      count={selectedItems.length}
      hasPath
      api={checkDelWorkSet}
      body={{ workset_ids: selectedItems }}
      actionName="삭제"
      icon="IC_delete_w"
      type="secondary_modal_btn"
      blackBackground={20}
      tableModal
    />
  );
};

export const CheckWorkSetList = ({ tab }) => {
  const [selectedObj, setSelectedObj] = useState({
    selectedItems: [],
    lastSelectedItem: null,
  });
  const [tableContents, setTableContents] = useState({});
  const modalRef = useRef(null);

  const {
    urlParams: { sensor_code, gt_code },
  } = useDynamicRouting();

  const COLS = {
    FULL: [
      { name: "name", ko: "작업 셋 이름" },
      {
        type: "num",
        name: "gt_cnt",
        ko: "데이터 수",
      },
      { type: "arr", name: "workers", ko: "작업자" },
      { type: "arr", name: "checkers", ko: "검수자" },
      {
        type: "member",
        name: "dist_rep",
        ko: "배분 담당자",
      },
      { name: "dist_date", ko: "배분날짜" },
    ],
    CAM_SEG: [
      { name: "check_req_t", ko: "최근 검수요청시간" },
      { name: "return_t", ko: "최근 검수반려시간" },
    ],
    CAM_DET: [
      {
        type: "num",
        name: "person_obj_num",
        cols: "personObjNum",
        ko: "총 사람 객체 수",
      },
      {
        type: "num",
        name: "vessel_obj_num",
        cols: "vesselObjNum",
        ko: "총 선박 객체 수",
      },
      {
        type: "num",
        name: "buoy_obj_num",
        cols: "buoyObjNum",
        ko: "총 부표 객체 수",
      },
      {
        type: "num",
        name: "smoke_obj_num",
        cols: "smokeObjNum",
        ko: "총 연기 객체 수",
      },
      {
        type: "num",
        name: "ids_num",
        cols: "idsNum",
        ko: "track id 수",
      },
    ],
    ADD: [
      { name: "payment_date", ko: "최근 대금지급날짜" },
      { name: "payment_rate", ko: "대금지급비율" },
      { name: "check_date", ko: "최근 학습반영날짜" },
    ],
  };

  const { FULL, CAM_SEG, CAM_DET, ADD } = COLS;

  return (
    <TableButton
      ref={modalRef}
      cols={
        sensor_code === "cam"
          ? gt_code === "seg"
            ? [...FULL, ...CAM_SEG, ...ADD]
            : [...FULL, ...CAM_DET, ...ADD]
          : [...FULL, ...ADD]
      }
      select
      listQuery={checkWorkSetListQuery}
      actionName="작업 셋 리스트"
      listName="작업 셋"
      icon="IC_table_b"
      type="unfill"
      selectedObj={selectedObj}
      setSelectedObj={setSelectedObj}
      getTableContents={(data) => setTableContents(data)}
    >
      <CheckCreateWorkSet />
      <CheckModifyWorkSet selectedItems={selectedObj.selectedItems} tableContents={tableContents} />
      <CheckWorkSet selectedItems={selectedObj.selectedItems} />
      <CheckResetWorkSet selectedItems={selectedObj.selectedItems} />
      <CheckDelWorkSet selectedItems={selectedObj.selectedItems} />
    </TableButton>
  );
};

export const CheckInfo = ({ selectedItems }) => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const { listReload } = useReload();
  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  const [infoList, setInfoList] = useState([]);
  const [infoValues, setInfoValues] = useState([]);
  const [failType, setFailType] = useState(null);
  const [value, setValue] = useState({
    difficulty: null,
    unit_price: null,
    person_price: null,
    vessel_price: null,
    buoy_price: null,
    smoke_price: null,
    checker_id: null,
    worker_id: null,
    label_v: null,
  });
  const [resetValue, setResetValue] = useState([]);
  const [open, setOpen] = useState(false);

  /* 
    name: string
    ko: string
    type: "list" | "number" | "member" | "radio"
    data: any[] (type이 "radio"일 경우)
    gt: null | "seg" | "det"
  */
  const DIST_INFO = [
    { name: "difficulty", ko: "난이도", type: "list", gt: null },
    { name: "unit_price", ko: "단가 (장)", type: "number", gt: "seg" },
    { name: "person_price", ko: "단가 (사람)", type: "number", gt: "det" },
    { name: "vessel_price", ko: "단가 (선박)", type: "number", gt: "det" },
    { name: "buoy_price", ko: "단가 (부표)", type: "number", gt: "det" },
    { name: "smoke_price", ko: "단가 (매연)", type: "number", gt: "det" },
    { name: "checker_id", ko: "검수자", type: "member", gt: null },
    { name: "worker_id", ko: "작업자", type: "member", gt: null },
    { name: "label_v", ko: "라벨버전", type: "list", gt: null },
  ];

  const resetOpen = () => {
    // 0. 상태 리셋
    setValue({
      difficulty: null,
      unit_price: null,
      person_price: null,
      vessel_price: null,
      buoy_price: null,
      smoke_price: null,
      checker_id: null,
      worker_id: null,
      label_v: null,
    });
    setResetValue([]);
    setOpen(false);
    setModalStatus(null);
    setFailType(null);

    // 1. 정보 가져오기
    selectedItems.length !== 0 && getInfoList();

    // 2. 모달 열기
    openModal();
  };

  const getInfoList = async () => {
    setModalStatus("loading");

    try {
      // 선택박스 리스트
      const data = await checkGetInfo(urlInfo);
      setInfoList({
        ...data.values,
        ...{ difficulty: ["L-1", "L0", "L1", "L2", "L3", "L4", "L5"] },
      });

      // 기존값 리스트
      const dataValues = await checkInfoValues(urlInfo, { ids: selectedItems });

      setInfoValues(dataValues);
      setModalStatus(null);
    } catch (err) {
      console.log(err?.response);

      let err_detail = err?.response?.data?.detail || "";

      setModalStatus("hasError");
      setFailType(err_detail);
    }
  };

  const handleClick = async () => {
    setModalStatus("loading");

    const { unit_price, ...detValue } = value;
    const { person_price, vessel_price, buoy_price, smoke_price, ...segValue } = value;
    const member = {
      checker_id: JSON.parse(value.checker_id)?.uuid || null,
      worker_id: JSON.parse(value.worker_id)?.uuid || null,
    };
    const body =
      urlInfo.gt_code === "seg"
        ? { ...segValue, ...member, ids: selectedItems, reset: resetValue }
        : { ...detValue, ...member, ids: selectedItems, reset: resetValue };

    try {
      const data = await checkInfo(urlInfo, body);

      console.log(data);
      closeModal();
      listReload();
    } catch (err) {
      console.log(err?.response);

      let err_detail = err?.response?.data?.detail || "";
      setFailType(err_detail);
      setModalStatus("hasError");
    }
  };

  const makeList = (value, name) => {
    const arr = value
      .map((x) => (name === "checker_id" || name === "worker_id" ? x[1] : x))
      .map((x) => (x === null ? "" : `${x}`))
      .filter((x) => x);

    if (arr.length === 0) return null;

    return (
      <div className="leading-[1.8]">
        (기존값:&nbsp;
        {arr.map((x, i) => `${x}${i !== arr.length - 1 ? ", " : ""}`)})
      </div>
    );
  };

  return (
    <>
      <CommonButton name="배분정보 입력 및 삭제" icon="IC_add_w" onClick={resetOpen} />

      {modal &&
        (selectedItems.length === 0 ? (
          <EmptyModal closeModal={closeModal} />
        ) : (
          <SaveModal
            closeModal={closeModal}
            title="배분정보 입력 및 삭제"
            detail="삭제 항목을 체크하면 입력돼 있던 값이 삭제됩니다."
            count={selectedItems.length}
            modalStatus={modalStatus}
            handleClick={handleClick}
            failType={failType}
          >
            {DIST_INFO.map(
              (item, index) =>
                (!item.gt || item.gt === urlInfo.gt_code) && (
                  <div key={index} className="pb-[8px]">
                    <SelectWrap>
                      <SelectName name={item.ko} />
                      {(item.type === "member" || item.type === "list") && (
                        <>
                          <SelectTitle
                            onClick={() => setOpen(open ? null : item.name)}
                            name={
                              (item.type === "member"
                                ? JSON.parse(value[item.name])?.display_name
                                : value[item.name]) || "선택"
                            }
                            styleType="modal"
                          />
                          <SelectBox open={open === item.name} styleType="modal">
                            {infoList[item.name]?.map((x, i) => {
                              let checked = false;
                              let inputValue = "";
                              let displayValue = "";
                              if (item.type === "member") {
                                checked = JSON.parse(value[item.name])?.uuid === x.uuid;
                                inputValue = JSON.stringify(x);
                                displayValue = x.display_name;
                              } else {
                                checked = value[item.name] === x;
                                inputValue = x;
                                displayValue = x;
                              }

                              return (
                                <SelectItem key={i} styleType="list">
                                  <SelectRadio
                                    onChange={(e) => {
                                      if (resetValue.includes(item.name)) {
                                        setResetValue(resetValue.filter((x) => x !== item.name));
                                      }
                                      setValue({
                                        ...value,
                                        [item.name]: e.target.value,
                                      });
                                      setOpen(false);
                                    }}
                                    checked={checked}
                                    value={inputValue}
                                  />
                                  <span className="ml-[12px]">{displayValue}</span>
                                </SelectItem>
                              );
                            })}
                          </SelectBox>
                        </>
                      )}
                      {item.type === "radio" &&
                        item.data.map((x, i) => (
                          <SelectItem key={i}>
                            <SelectRadio
                              onChange={(e) => {
                                if (resetValue.includes(item.name)) {
                                  setResetValue(resetValue.filter((x) => x !== item.name));
                                }
                                setValue({
                                  ...value,
                                  [item.name]: e.target.value,
                                });
                              }}
                              checked={value?.[item] === x}
                              value={x}
                            />
                            <div className="pl-[8px]">{x}</div>
                          </SelectItem>
                        ))}
                      {item.type === "number" && (
                        <TextField
                          type="number"
                          hiddenLabel
                          size="small"
                          value={value[item.name] || ""}
                          onChange={(e) => {
                            if (resetValue.includes(item.name)) {
                              setResetValue(resetValue.filter((x) => x !== item.name));
                            }
                            setValue({
                              ...value,
                              [item.name]: e.target.value === "" ? null : e.target.value,
                            });
                          }}
                        />
                      )}
                    </SelectWrap>
                    <div className="flex gap-[8px] text-gray800">
                      <div className="w-[90px] flex-none">
                        <SelectItem>
                          <SelectCheckbox
                            onChange={() => {
                              if (resetValue.includes(item.name)) {
                                setResetValue(resetValue.filter((x) => x !== item.name));
                              } else {
                                setResetValue([...resetValue, item.name]);
                                setValue({
                                  ...value,
                                  [item.name]: null,
                                });
                              }
                            }}
                            checked={resetValue.includes(item.name)}
                            value={item.name}
                          />
                          <div className="pl-[4px]">삭제</div>
                        </SelectItem>
                      </div>
                      {infoValues?.[item.name] && makeList(infoValues[item.name], item.name)}
                    </div>
                  </div>
                )
            )}
          </SaveModal>
        ))}
    </>
  );
};

export const CheckAdd = ({ selectedItems }) => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const [failType, setFailType] = useState("");
  const [required, setRequired] = useState(null);
  const [value, setValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [infoList, setInfoList] = useState([]);

  const { listReload } = useReload();
  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  const resetOpen = () => {
    // 0. 상태 리셋
    setOpen(false);
    setValue(null);
    setModalStatus(null);
    setFailType(null);
    setRequired(false);

    // 1. 작업 셋 리스트 가져오기
    getInfoList();

    // 2. 모달 열기
    openModal();
  };

  const getInfoList = async () => {
    setModalStatus("loading");

    try {
      const data = await checkDistWorkSetList(urlInfo);

      console.log(data);
      setInfoList(data.values);
      setModalStatus(null);
    } catch (err) {
      console.log(err?.response);

      let err_detail = err?.response?.data?.detail || "";

      setModalStatus("hasError");
      setFailType(err_detail);
    }
  };

  const checkInput = () => {
    if (!value) {
      setModalStatus("hasError");
      setFailType("필수 정보를 입력해 주세요.");
      setRequired(true);
    } else {
      handleClick();
    }
  };

  const handleClick = async () => {
    setModalStatus("loading");

    try {
      const data = await checkAdd(urlInfo, {
        ids: selectedItems,
        workset_id: value,
      });

      console.log(data);
      closeModal();
      listReload();
    } catch (err) {
      console.log(err?.response);

      let err_detail = err?.response?.data?.detail || "";

      setModalStatus("hasError");
      setFailType(err_detail);
    }
  };

  return (
    <>
      <CommonButton name="작업 셋에 추가" icon="IC_add_w" onClick={resetOpen} />

      {modal &&
        (selectedItems.length === 0 ? (
          <EmptyModal closeModal={closeModal} />
        ) : (
          <SaveModal
            closeModal={closeModal}
            title="작업 셋에 추가"
            modalStatus={modalStatus}
            handleClick={checkInput}
            failType={failType}
          >
            <SelectWrap required={required && !value}>
              <SelectName name="작업 셋 이름" />
              <SelectTitle
                onClick={() => setOpen(open ? null : "set_id")}
                name={value || "선택"}
                styleType="modal"
              />
              <SelectBox open={open === "set_id"} styleType="modal">
                {infoList?.map(([v, b], i) => (
                  <SelectItem key={i} styleType="list">
                    <SelectRadio
                      onChange={(e) => {
                        setValue(e.target.value);
                        setOpen(false);
                      }}
                      checked={value === v}
                      value={v}
                      bool={b}
                    />
                    <span className={clsx("ml-[12px]", b === false && "text-gray400")}>{v}</span>
                  </SelectItem>
                ))}
              </SelectBox>
            </SelectWrap>
          </SaveModal>
        ))}
    </>
  );
};

export const CheckRemove = ({ selectedItems }) => {
  return (
    <ConfirmButton
      count={selectedItems.length}
      api={checkRemove}
      hasPath
      body={{ ids: selectedItems }}
      actionName="작업 셋에서 제외"
      icon="IC_remove_w"
      type="secondary"
    />
  );
};

export const CheckDel = ({ contents, selectedItems }) => {
  return (
    <DelCheckButton
      api={checkDelCheck}
      contents={contents}
      selectedItems={selectedItems}
      type="secondary"
      icon="IC_delete_w"
    />
  );
};

export const CheckDistReset = ({ selectedItems }) => {
  return (
    <ConfirmButton
      count={selectedItems.length}
      api={checkDistReset}
      hasPath
      body={{ ids: selectedItems }}
      actionName="배분회수"
      icon="IC_remove_w"
      type="secondary"
    />
  );
};

export const CheckModifyCheckerReq = ({ selectedItems }) => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const { listReload } = useReload();

  const [failType, setFailType] = useState(null);
  const [required, setRequired] = useState(null);
  const [value, setValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [infoList, setInfoList] = useState([]);

  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  const resetOpen = () => {
    // 0. 상태 리셋
    setValue(null);
    setModalStatus(null);
    setFailType(null);
    setRequired(false);

    // 1. 리스트 정보 가져오기
    getInfoList();

    // 3. 모달 열기
    openModal();
  };

  const getInfoList = async () => {
    setModalStatus("loading");

    try {
      const data = await checkGetInfo(urlInfo);

      console.log(data);
      setInfoList(data.values.checker_id);
      setModalStatus(null);
    } catch (err) {
      console.log(err?.response);

      let err_detail = err?.response?.data?.detail || "";

      setModalStatus("hasError");
      setFailType(err_detail);
    }
  };

  const checkInput = (id) => {
    setModalStatus("hasError");

    if (!value) {
      setModalStatus("hasError");
      setFailType("필수 정보를 입력해 주세요.");
      setRequired(true);
    } else {
      handleClick(id);
    }
  };

  const handleClick = async () => {
    setModalStatus("loading");

    try {
      const data = await checkModifyCheckerReq(urlInfo, {
        checker_id: JSON.parse(value).uuid,
        ids: selectedItems,
      });

      console.log(data);
      closeModal();
      listReload();
    } catch (err) {
      console.log(err?.response);

      let err_detail = err?.response?.data?.detail || "";
      setFailType(err_detail);
      setModalStatus("hasError");
    }
  };

  return (
    <>
      <CommonButton name="검수자 변경 요청" icon="IC_add_w" onClick={resetOpen} />

      {modal &&
        (selectedItems.length === 0 ? (
          <EmptyModal closeModal={closeModal} />
        ) : (
          <SaveModal
            closeModal={closeModal}
            title="검수자 변경 요청 정보 입력"
            count={selectedItems.length}
            modalStatus={modalStatus}
            handleClick={checkInput}
            failType={failType}
          >
            <SelectWrap required={required && !value}>
              <SelectName name="검수자 이름" />
              <SelectTitle
                onClick={() => setOpen(open ? null : "checker")}
                name={JSON.parse(value)?.display_name || "선택"}
                styleType="modal"
              />
              <SelectBox open={open === "checker"} styleType="modal">
                {infoList?.map((x, i) => (
                  <SelectItem key={i} styleType="list">
                    <SelectRadio
                      onChange={(e) => {
                        setValue(e.target.value);
                        setOpen(false);
                      }}
                      checked={JSON.parse(value)?.uuid === x.uuid}
                      value={JSON.stringify(x)}
                    />
                    <span className="ml-[12px]">{x.display_name}</span>
                  </SelectItem>
                ))}
              </SelectBox>
            </SelectWrap>
          </SaveModal>
        ))}
    </>
  );
};

export const CheckModifyChecker = ({ selectedItems }) => {
  return (
    <AcceptButton
      count={selectedItems.length}
      api={checkModifyChecker}
      body={{ ids: selectedItems }}
      actionName="검수자 변경 확인"
      icon="IC_complete_w"
    />
  );
};

export const CheckModifyCheckerAll = ({ count }) => {
  return (
    <AcceptButton
      count={count}
      api={checkModifyCheckerAll}
      actionName="전체 검수자 변경 확인"
      icon="IC_complete_w"
    />
  );
};

export const CheckWorkStartReset = ({ selectedItems }) => {
  return (
    <ConfirmButton
      api={checkWorkStartReset}
      count={selectedItems.length}
      hasPath
      body={{ ids: selectedItems }}
      actionName="작업시작 취소"
      icon="IC_reset_w"
      type="secondary"
    />
  );
};

export const DownloadGtPsdAll = ({ count }) => {
  const {
    urlParams: { set_id },
  } = useDynamicRouting();

  return (
    <DownloadAllButton
      count={count}
      api={checkGtPsdAllDwlLink}
      dlUrl={`/check/cam/seg/datas/${set_id}/gt/dwl/`}
      actionName="전체 psd 데이터 다운로드"
      icon="IC_download_w"
    />
  );
};

export const DownloadGtPsd = ({ selectedItems }) => {
  const {
    urlParams: { set_id },
  } = useDynamicRouting();

  return (
    <DownloadButton
      count={selectedItems.length}
      api={checkGtPsdDwlLink}
      hasPath
      body={{ ids: selectedItems }}
      dlUrl={`/check/cam/seg/datas/${set_id}/gt/dwl/`}
      actionName="psd 데이터 다운로드"
      icon="IC_download_w"
    />
  );
};

export const DownloadBlendAll = ({ count }) => {
  const {
    urlParams: { set_id },
  } = useDynamicRouting();

  return (
    <DownloadAllButton
      count={count}
      api={checkBlendAllDwlLink}
      dlUrl={`/check/cam/det/datas/${set_id}/blend/dwl/`}
      actionName="전체 원본+라벨 데이터 다운로드"
      icon="IC_download_w"
    />
  );
};

export const DownloadBlend = ({ selectedItems }) => {
  const {
    urlParams: { set_id },
  } = useDynamicRouting();

  return (
    <DownloadButton
      count={selectedItems.length}
      api={checkBlendDwlLink}
      hasPath
      body={{ ids: selectedItems }}
      dlUrl={`/check/cam/det/datas/${set_id}/blend/dwl/`}
      actionName="원본+라벨 데이터 다운로드"
      icon="IC_download_w"
    />
  );
};

export const DownloadGtLabelAll = ({ count }) => {
  const {
    urlParams: { set_id },
  } = useDynamicRouting();

  return (
    <DownloadAllButton
      count={count}
      api={checkLabelAllDwlLink}
      dlUrl={`/check/rad/seg/datas/${set_id}/gt/dwl/`}
      actionName="전체 라벨 데이터 다운로드"
      icon="IC_download_w"
    />
  );
};

export const DownloadGtLabel = ({ selectedItems }) => {
  const {
    urlParams: { set_id },
  } = useDynamicRouting();

  return (
    <DownloadButton
      count={selectedItems.length}
      api={checkLabelDwlLink}
      hasPath
      body={{ ids: selectedItems }}
      dlUrl={`/check/rad/seg/datas/${set_id}/gt/dwl/`}
      actionName="라벨 데이터 다운로드"
      icon="IC_download_w"
    />
  );
};

export const DownloadGtJsonAll = ({ count }) => {
  const {
    urlParams: { set_id },
  } = useDynamicRouting();

  return (
    <DownloadAllButton
      count={count}
      api={checkGtJsonAllDwlLink}
      dlUrl={`/check/cam/det/datas/${set_id}/gt/dwl/`}
      actionName="전체 JSON 데이터 다운로드"
      icon="IC_download_w"
    />
  );
};

export const DownloadGtJson = ({ selectedItems }) => {
  const {
    urlParams: { set_id },
  } = useDynamicRouting();

  return (
    <DownloadButton
      count={selectedItems.length}
      api={checkGtJsonDwlLink}
      hasPath
      body={{ ids: selectedItems }}
      dlUrl={`/check/cam/det/datas/${set_id}/gt/dwl/`}
      actionName="JSON 데이터 다운로드"
      icon="IC_download_w"
    />
  );
};

export const CheckGtReUpload = () => {
  const {
    urlParams: { sensor_code, gt_code },
  } = useDynamicRouting();

  const ActionName = {
    cam: {
      seg: "psd",
      det: "JSON",
    },
    rad: {
      seg: "라벨",
    },
  };

  const Accept = {
    cam: {
      seg: "psd",
      det: "json",
    },
    rad: {
      seg: "png",
    },
  };

  return (
    <FloatingButton
      api={checkGtReUl}
      actionName={`${ActionName[sensor_code][gt_code]} 데이터 업로드`}
      accept={Accept[sensor_code][gt_code]}
      icon="IC_upload_w"
    />
  );
};

export const SetPass = ({ selectedItems }) => {
  return (
    <ConfirmButton
      count={selectedItems.length}
      api={checkPass}
      hasPath
      body={{ ids: selectedItems }}
      actionName="검수통과"
      icon="IC_complete_w"
    />
  );
};

export const SetReturn = ({ selectedItems }) => {
  return (
    <ConfirmButton
      count={selectedItems.length}
      api={checkReturn}
      hasPath
      body={{ ids: selectedItems }}
      actionName="검수반려"
      icon="IC_remove_w"
      type="secondary"
    />
  );
};

export const SetRework = ({ selectedItems }) => {
  return (
    <ConfirmButton
      count={selectedItems.length}
      api={checkRework}
      hasPath
      body={{ ids: selectedItems }}
      actionName="재작업"
      icon="IC_add_w"
      type="secondary"
    />
  );
};

export const CheckReset = ({ selectedItems }) => {
  return (
    <ConfirmButton
      count={selectedItems.length}
      api={checkReset}
      hasPath
      body={{ ids: selectedItems }}
      actionName="검수리셋"
      icon="IC_reset_w"
      type="secondary"
    />
  );
};

export const CheckCancel = ({ selectedItems }) => {
  return (
    <AcceptButton
      count={selectedItems.length}
      api={checkCancel}
      body={{ ids: selectedItems }}
      actionName="작업회수 확인"
      icon="IC_complete_w"
    />
  );
};

export const UploadClctGuide = () => {
  return (
    <FloatingButton
      api={checkClctGuideUl}
      actionName="작업 가이드 업로드"
      accept={[
        "zip",
        "document",
        "presentation",
        "sheet",
        "jpeg",
        "json",
        "pdf",
        "png",
        "psd",
        "text",
      ]}
      ko
      icon="IC_upload_w"
    />
  );
};

export const UploadGuide = () => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const accept = [
    "zip",
    "document",
    "presentation",
    "sheet",
    "jpeg",
    "json",
    "pdf",
    "png",
    "psd",
    "text",
  ];

  const { process } = useFileUploader();
  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  const fileInput = useRef();
  const [floatingItemContents, setFloatingItemContents] = useState(null);
  const [selectFile, setSelectFile] = useState(null);

  const [infoList, setInfoList] = useState([]);
  const [failType, setFailType] = useState("");
  const [required, setRequired] = useState(null);
  const [value, setValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [checkFileResult, setCheckFileResult] = useState(false);

  // 1. 버튼 클릭시 파일 선택 창 열기
  const handleFileInput = async (e) => {
    resetState();

    const files = e.target.files;
    const newFileList = Array.from(files);
    const len = files.length;

    // 2. 선택된 파일이 없으면 return
    if (len < 1) return null;

    // 3. 업로드 파일 체크
    const isFailed = checkFile(len, files, accept, true);
    if (isFailed) {
      setCheckFileResult(isFailed);
    } else {
      let arr = [];
      for (let i = 0; i < len; i++) {
        arr = [
          ...arr,
          {
            filename: files[i].name,
            floatingStatus: "대기",
          },
        ];
      }
      setFloatingItemContents(arr);
      setSelectFile(newFileList);

      // 4. 작업 셋 리스트 가져오기
      getInfoList();
    }

    // 5. 모달 열기
    openModal();
  };

  const resetState = () => {
    setCheckFileResult(false);
    setValue(null);
    setFailType(null);
    setRequired(false);
    setModalStatus(null);
  };

  const getInfoList = async () => {
    setModalStatus("loading");

    try {
      const data = await checkWorkSetList(urlInfo);

      console.log(data);
      setInfoList(data.results);
      setModalStatus(null);
    } catch (err) {
      console.log(err?.response);

      let err_detail = err?.response?.data?.detail || "";

      setModalStatus("hasError");
      setFailType(err_detail);
    }
  };

  const checkInput = () => {
    setModalStatus("hasError");

    if (!value) {
      setFailType("필수 정보를 입력해 주세요.");
      setRequired(true);
    }
  };

  const handleUploadFile = async (rowId) => {
    closeModal();

    process({
      rowId,
      urlInfo,
      api: checkGuideUl,
      selectFile,
      workset_id: JSON.parse(value).id,
    });
  };

  return (
    <>
      <input
        type="file"
        name="file"
        ref={fileInput}
        onChange={handleFileInput}
        onClick={(e) => (e.target.value = null)}
        style={{ display: "none" }}
        multiple
      />
      <CommonButton
        name="작업 가이드 업로드"
        icon="IC_upload_w"
        onClick={() => fileInput.current.click()}
      />

      {modal &&
        (checkFileResult ? (
          <AcceptModal closeModal={closeModal} accept={accept} checkFileResult={checkFileResult} />
        ) : (
          <SaveModal
            title="작업 가이드 업로드 정보 확인"
            count={selectFile.length}
            closeModal={closeModal}
            modalStatus={modalStatus}
            handleClick={handleUploadFile}
            value={value}
            checkInput={checkInput}
            panelname="작업 가이드 업로드"
            floatingItemContents={floatingItemContents}
            failType={failType}
          >
            <SelectWrap required={required && !value}>
              <SelectName name="작업 셋 이름" />
              <SelectTitle
                onClick={() => setOpen(open ? null : "set_id")}
                name={JSON.parse(value)?.name || "선택"}
                styleType="modal"
              />
              <SelectBox open={open === "set_id"} styleType="modal">
                {infoList.map((x, i) => (
                  <SelectItem key={i} styleType="list">
                    <SelectRadio
                      onChange={(e) => {
                        setValue(e.target.value);
                        setOpen(false);
                      }}
                      checked={JSON.parse(value)?.name === x.name}
                      value={JSON.stringify(x)}
                    />
                    <span className="ml-[12px]">{x.name}</span>
                  </SelectItem>
                ))}
              </SelectBox>
            </SelectWrap>
          </SaveModal>
        ))}
    </>
  );
};

export const DownloadClctGuide = ({ selectedItems }) => {
  const {
    urlParams: { sensor_code, gt_code, set_id },
  } = useDynamicRouting();

  return (
    <DownloadButton
      count={selectedItems.length}
      api={checkClctGuideDwlLink}
      hasPath
      body={{ ids: selectedItems }}
      dlUrl={`/check/${sensor_code}/${gt_code}/clct_guides/${set_id}/dwl/`}
      actionName="작업 가이드 다운로드"
      icon="IC_download_w"
    />
  );
};

export const DownloadGuide = ({ selectedItems }) => {
  const {
    urlParams: { sensor_code, gt_code, set_id },
  } = useDynamicRouting();

  return (
    <DownloadButton
      count={selectedItems.length}
      api={checkGuideDwlLink}
      hasPath
      body={{ ids: selectedItems }}
      dlUrl={`/check/${sensor_code}/${gt_code}/guides/${set_id}/dwl/`}
      actionName="작업 가이드 다운로드"
      icon="IC_download_w"
    />
  );
};

export const CheckClctGuideDel = ({ selectedItems }) => {
  return (
    <ConfirmButton
      count={selectedItems.length}
      api={checkClctGuideDelete}
      hasPath
      body={{ ids: selectedItems }}
      actionName="작업 가이드 삭제"
      icon="IC_delete_w"
      type="secondary"
    />
  );
};

export const CheckGuideDel = ({ selectedItems }) => {
  return (
    <ConfirmButton
      count={selectedItems.length}
      api={checkGuideDelete}
      hasPath
      body={{ ids: selectedItems }}
      actionName="작업 가이드 삭제"
      icon="IC_delete_w"
      type="secondary"
    />
  );
};

export const CheckQnaAnswer = ({ item }) => {
  return (
    <QnaButton
      id={item.id}
      type="answer"
      api={checkQnaAnswer}
      title="답변등록"
      icon="IC_add_w"
      placeholder="답변을 입력해 주세요."
    />
  );
};

export const CheckMakeOrigVideo = () => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const { process } = useBatchIdProcessor();
  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  const [floatingItemContents, setFloatingItemContents] = useState(null);

  const [failType, setFailType] = useState("");
  const [value, setValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [required, setRequired] = useState(null);
  const [sensorList, setSensorList] = useState([]);

  const resetOpen = () => {
    // 0. 상태 리셋
    setModalStatus(null);
    setValue(null);
    setOpen(false);
    setFailType(null);
    setRequired(false);

    // 1. 리스트 정보 가져오기
    getSensorList();

    // 3. 모달 열기
    openModal();
  };

  const getSensorList = async () => {
    setModalStatus("loading");

    try {
      const data = await checkOrigVideoGetInfo(urlInfo);
      console.log(data);

      setSensorList(data.values.sensor_name);
      setModalStatus(null);
    } catch (err) {
      console.log(err?.response);

      let err_detail = err?.response?.data?.detail || "";

      setModalStatus("hasError");
      setFailType(err_detail);
    }
  };

  const addItem = (value) => {
    let uploadArr = [
      {
        sensorName: value,
        floatingStatus: "생성",
      },
    ];

    setFloatingItemContents(uploadArr);
  };

  const checkInput = () => {
    setModalStatus("hasError");

    if (!value) {
      setFailType("필수 정보를 입력해 주세요.");
      setRequired(true);
    }
  };

  const handleClick = async (rowId) => {
    if (!value) {
      setModalStatus("hasError");
      setFailType("필수 정보를 입력해 주세요.");
      setRequired(true);
      return;
    }

    closeModal();

    process({
      rowId,
      urlInfo,
      len: 0,
      api: checkMakeOrigVideo,
      body: { sensor_name: value },
    });
  };

  return (
    <>
      <CommonButton name="참고 영상 생성" onClick={resetOpen} icon="IC_add_w" type="modal_btn" />

      {modal && (
        <SaveModal
          title="참고 영상 생성 정보 확인"
          closeModal={closeModal}
          modalStatus={modalStatus}
          handleClick={handleClick}
          value={value}
          checkInput={checkInput}
          panelname="참고 영상 생성"
          floatingItemContents={floatingItemContents}
          failType={failType}
          blackBackground={20}
        >
          <SelectWrap required={required && !value}>
            <SelectName name="센서번호" />
            <SelectTitle onClick={() => setOpen(!open)} name={value || "선택"} styleType="modal" />
            <SelectBox open={open} styleType="modal">
              {sensorList.map((item, i) => (
                <SelectItem key={i} item={item} pagination styleType="list">
                  <SelectRadio
                    onChange={(e) => {
                      setValue(e.target.value);
                      setOpen(false);
                      addItem(e.target.value);
                    }}
                    checked={value === item.toString()}
                    value={item}
                  />
                </SelectItem>
              ))}
            </SelectBox>
          </SelectWrap>
        </SaveModal>
      )}
    </>
  );
};

export const DownloadOrigVideo = ({ selectedItems }) => {
  const {
    urlParams: { sensor_code, gt_code, set_id },
  } = useDynamicRouting();

  return (
    <DownloadButton
      count={selectedItems.length}
      api={checkOrigVideoDwlLink}
      hasPath
      body={{ ids: selectedItems }}
      dlUrl={`/check/${sensor_code}/${gt_code}/datas/${set_id}/videos/dwl/`}
      actionName="참고 영상 다운로드"
      icon="IC_download_w"
      type="modal_btn"
      blackBackground={20}
    />
  );
};

export const CheckOrigVideoList = () => {
  const [selectedObj, setSelectedObj] = useState({
    selectedItems: [],
    lastSelectedItem: null,
  });
  const modalRef = useRef(null);

  return (
    <TableButton
      ref={modalRef}
      cols={REF_VIDEO_COL}
      select
      listQuery={checkOrigVideoListQuery}
      actionName="참고 영상 리스트"
      listName="참고 영상"
      icon="IC_table_b"
      type="unfill"
      selectedObj={selectedObj}
      setSelectedObj={setSelectedObj}
    >
      <CheckMakeOrigVideo />
      <DownloadOrigVideo selectedItems={selectedObj.selectedItems} />
    </TableButton>
  );
};
