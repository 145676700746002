export const yearHeader = ["year", "cam_seg", "cam_det", "rad_seg"];
export const pathHeader = ["path", "lrn_data", "ratio"];
export const portHeader = ["port", "lrn_data", "ratio"];
export const berthHeader = ["berth", "lrn_data", "ratio"];
export const vesselHeader = ["vessel", "lrn_data", "ratio"];
export const condHeader = ["cond", "data", "ratio"];
export const infHeader = ["berth", "val", "can", "val_ratio"];
export const monthHeader = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const workerHeader = ["difficulty", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const monthWorkHeader = [
  "worker",
  "L-1",
  "L0",
  "L1",
  "L2",
  "L3",
  "L4",
  "L5",
  "total",
  "total_price",
];
export const distHeader = [
  "path",
  "port",
  "berth",
  "vessel",
  "date",
  "dist_date",
  "dist_rep",
  "dist_cnt",
  "difficulties",
  "person_prices",
  "vessel_prices",
  "buoy_prices",
  "smoke_prices",
  "check_cnt",
  "total_price",
];
export const distRadHeader = [
  "path",
  "vessel",
  "date",
  "dist_date",
  "dist_rep",
  "dist_cnt",
  "difficulties",
  "person_prices",
  "vessel_prices",
  "buoy_prices",
  "smoke_prices",
  "check_cnt",
  "total_price",
];
export const evalvalHeader = ["vld_type", "dist_cnt", "dist_ratio"];
export const checkPortEvalHeader = [
  "port",
  "berth",
  "eval_total",
  "not_dist_cnt",
  "dist_cnt",
  "dist_ratio",
  "check_cnt",
  "check_ratio",
];
export const checkPortValHeader = [
  "port",
  "berth",
  "val_total",
  "not_dist_cnt",
  "dist_cnt",
  "dist_ratio",
  "check_cnt",
  "check_ratio",
];
export const checkSailEvalHeader = [
  "vessel",
  "eval_total",
  "not_dist_cnt",
  "dist_cnt",
  "dist_ratio",
  "check_cnt",
  "check_ratio",
];
export const checkSailValHeader = [
  "vessel",
  "val_total",
  "not_dist_cnt",
  "dist_cnt",
  "dist_ratio",
  "check_cnt",
  "check_ratio",
];

export const STATS_DICT = [
  { value: "cam_seg", name: "Camera Segmentation" },
  { value: "cam_det", name: "Camera Detection" },
  { value: "rad_seg", name: "Radar Segmentation" },
  { value: "KRUSN", name: "KRUSN" },
  { value: "KRYOS", name: "KRYOS" },
  { value: "KRINC", name: "KRINC" },
  { value: "total", name: "전체" },
  { value: "all", name: "학습용 데이터" },
  { value: "stand_by", name: "반출" },
  { value: "working", name: "반입" },
  { value: "finished", name: "학습 데이터" },
  { value: "port", name: "항만" },
  { value: "berth", name: "선석" },
  { value: "sail", name: "운항" },
  { value: "snow", name: "눈" },
  { value: "fog", name: "안개" },
  { value: "rain", name: "비" },
  { value: "light", name: "조명" },
  { value: "backlight", name: "역광" },
  { value: "night", name: "밤" },
  { value: "smoke", name: "연기" },
  { value: "eval", name: "평가용 데이터" },
  { value: "val", name: "학습용 데이터" },
  { value: "can", name: "대기 데이터" },
];

export const STATS_COLUMN_LIST = [
  { type: "find", name: "year", ko: "연도" },
  {
    type: "num",
    name: "cam_seg",
    ko: "Camera Segmentation",
  },
  {
    type: "num",
    name: "cam_det",
    ko: "Camera Detection",
  },
  {
    type: "num",
    name: "rad_seg",
    ko: "Radar Segmentation",
  },
  {
    name: "clct_name",
    ko: "수집 데이터 셋",
  },
  { type: "find", name: "path", ko: "수집경로" },
  { type: "find", name: "port", ko: "항만" },
  { type: "find", name: "berth", ko: "선석" },
  { type: "find", name: "vessel", ko: "선박 또는 지역" },
  {
    name: "date",
    ko: "수집날짜",
  },
  {
    type: "find",
    name: "cond",
    ko: "수집환경",
  },
  {
    type: "num",
    name: "snow",
    ko: "눈",
  },
  {
    type: "num",
    name: "rain",
    ko: "비",
  },
  {
    type: "num",
    name: "fog",
    ko: "안개",
  },
  {
    type: "num",
    name: "light",
    ko: "조명",
  },
  {
    type: "num",
    name: "backlight",
    ko: "역광",
  },
  {
    type: "num",
    name: "night",
    ko: "밤",
  },
  {
    type: "num",
    name: "smoke",
    ko: "연기",
  },
  { type: "find", name: "vld_type", ko: "선별 유형" },
  {
    type: "num",
    name: "data",
    ko: "데이터 수",
  },
  {
    type: "num",
    name: "can",
    ko: "대기 데이터",
  },
  {
    type: "num",
    name: "val",
    ko: "학습용 데이터",
  },
  {
    type: "ratio",
    name: "val_ratio",
    ko: "학습용 데이터 비율",
  },
  {
    type: "strong",
    name: "val_total",
    ko: "전체 학습용 데이터",
  },
  {
    type: "num",
    name: "eval",
    ko: "평가용 데이터",
  },
  {
    type: "strong",
    name: "eval_total",
    ko: "전체 평가용 데이터",
  },
  {
    name: "dist_date",
    ko: "배분 날짜",
  },
  {
    name: "dist_rep",
    ko: "배분 담당자",
  },
  {
    name: "worker",
    ko: "작업자",
  },
  {
    type: "num",
    name: "not_dist_cnt",
    ko: "미배분 데이터",
  },
  {
    type: "num",
    name: "dist_cnt",
    ko: "배분 데이터",
  },
  { type: "ratio", name: "dist_ratio", ko: "배분 비율" },
  {
    type: "find",
    name: "difficulty",
    ko: "난이도",
  },
  {
    type: "arr",
    name: "difficulties",
    ko: "난이도",
  },
  {
    type: "num",
    name: "L-1",
    ko: "L-1",
  },
  {
    type: "num",
    name: "L0",
    ko: "L0",
  },
  {
    type: "num",
    name: "L1",
    ko: "L1",
  },
  {
    type: "num",
    name: "L2",
    ko: "L2",
  },
  {
    type: "num",
    name: "L3",
    ko: "L3",
  },
  {
    type: "num",
    name: "L4",
    ko: "L4",
  },
  {
    type: "num",
    name: "L5",
    ko: "L5",
  },
  {
    type: "arr",
    name: "person_prices",
    ko: "단가(사람)",
  },
  {
    type: "arr",
    name: "vessel_prices",
    ko: "단가(선박)",
  },
  {
    type: "arr",
    name: "buoy_prices",
    ko: "단가(부표)",
  },
  {
    type: "arr",
    name: "smoke_prices",
    ko: "단가(연기)",
  },
  {
    type: "num",
    name: "check_cnt",
    ko: "검수통과 데이터",
  },
  { type: "ratio", name: "check_ratio", ko: "검수통과 비율" },
  {
    name: "check_date",
    ko: "검수 날짜",
  },
  {
    name: "checker",
    ko: "검수자",
  },
  {
    type: "num",
    name: "lrn_data",
    ko: "학습 데이터",
  },
  { type: "ratio", name: "ratio", ko: "비율" },
  {
    type: "strong",
    name: "total",
    ko: "총 데이터 수",
  },
  {
    type: "strong",
    name: "total_price",
    ko: "총 금액(원)",
  },
  {
    type: "num",
    name: 1,
    ko: "1월",
  },
  {
    type: "num",
    name: 2,
    ko: "2월",
  },
  {
    type: "num",
    name: 3,
    ko: "3월",
  },
  {
    type: "num",
    name: 4,
    ko: "4월",
  },
  {
    type: "num",
    name: 5,
    ko: "5월",
  },
  {
    type: "num",
    name: 6,
    ko: "6월",
  },
  {
    type: "num",
    name: 7,
    ko: "7월",
  },
  {
    type: "num",
    name: 8,
    ko: "8월",
  },
  {
    type: "num",
    name: 9,
    ko: "9월",
  },
  {
    type: "num",
    name: 10,
    ko: "10월",
  },
  {
    type: "num",
    name: 11,
    ko: "11월",
  },
  {
    type: "num",
    name: 12,
    ko: "12월",
  },
];
