import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { useRecoilValueLoadable, useSetRecoilState } from "recoil";
import CommonTemplate from "../../components/templates/CommonTemplate";
import { SelectCnt } from "../../components/atoms/CommonAtoms";
import { useUpdate, useReload } from "../../lib/hooks";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { Loader } from "../../components/atoms/Status";
import { vldFilter } from "./Vld.apis";
import {
  makeImgTypeList,
  VLD_DATA_COLS,
  VLD_RADSEG_TAB,
  VLD_CAMDET_TAB,
  VLD_CAMSEG_TAB,
  VLD_TYPE_CODE,
} from "./Vld.constants";
import {
  vldCntQuery,
  vldIdsQuery,
  vldListQuery,
  vldPreviewQuery,
  vldTagsState,
} from "./Vld.stores";
import {
  VldAddTag,
  DownloadGps,
  VldMaskRgs,
  VldSegAuto,
  VldDetAuto,
  DownloadVldResult,
  VldInf,
  VldMapUpload,
  DownloadOrig,
  VldReset,
  SetVldTag,
  VldPropList,
  SetAllVldTag,
  VldDelCheck,
  DownloadOrigAll,
} from "./VldActions";
import { axiosInstance } from "../../components/templates/RootErrorBoundary";

const VldDataPage = () => {
  const { urlParams, queryParams } = useDynamicRouting();
  const { sensor_code, gt_code, set_id } = urlParams;
  const { tab, view, t } = queryParams;

  const { state: state_cnt, contents: contents_cnt } = useRecoilValueLoadable(
    vldCntQuery({ ...urlParams, tab, t })
  );
  const { state: state_ids, contents: contents_ids } = useRecoilValueLoadable(
    vldIdsQuery({ sensor_code, gt_code, set_id, ...queryParams })
  );

  const setVldTags = useSetRecoilState(vldTagsState);
  const { listReload } = useReload();

  // 자동 선별 진행 중 표시
  const loadingInfo = automationInfo.get(sensor_code, gt_code);

  const loadingRef = useRef(null);

  const afterActionCallback = () => {
    loadingRef.current.checkAutomation();
  };

  const afterAutomationFinish = () => {
    listReload();
  };

  return (
    <>
      {loadingInfo && loadingInfo.tabs.includes(tab) ? (
        <AutomationLoading
          ref={loadingRef}
          url={loadingInfo.url(set_id)}
          data_key={loadingInfo.data_key}
          message={loadingInfo.message}
          afterAutomationFinish={afterAutomationFinish}
        />
      ) : null}
      <CommonTemplate
        listQuery={vldListQuery}
        state_cnt={state_cnt}
        contents_cnt={contents_cnt}
        contents_ids={state_ids === "hasValue" ? contents_ids.ids : []}
        filterApi={vldFilter}
        previewQuery={vldPreviewQuery}
        fullColumnData={VLD_DATA_COLS[`${sensor_code}`].tab1}
        columnData={VLD_DATA_COLS[`${sensor_code}`][`tab${tab}`] ?? []}
        hideColumn={VLD_DATA_COLS[`${sensor_code}`].hide.table[`tab${tab}`]}
        hideThumbColumn={VLD_DATA_COLS[`${sensor_code}`].hide.thumb[`tab${tab}`]}
        tabData={
          sensor_code === "rad"
            ? VLD_RADSEG_TAB
            : gt_code === "det"
              ? VLD_CAMDET_TAB
              : VLD_CAMSEG_TAB
        }
        groupColumnData={
          sensor_code === "rad"
            ? VLD_RADSEG_TAB
            : gt_code === "det"
              ? VLD_CAMDET_TAB
              : VLD_CAMSEG_TAB
        }
        viewData
        imgTypeData={makeImgTypeList(sensor_code)}
        statusCodeData={VLD_TYPE_CODE}
        setVldTags={setVldTags}
        tagType={tab === "2" ? "eval" : view === "thumb" && tab === "3" && "val"}
        actions={(data) =>
          renderActions({
            sensor_code,
            gt_code,
            tab,
            set_id,
            ...data,
            afterActionCallback,
          })
        }
        actionsNoPermit={(data) => renderActionsNoPermit({ sensor_code, gt_code, tab })}
      />
    </>
  );
};

const renderActions = ({
  sensor_code,
  gt_code,
  tab,
  set_id,
  view,
  selectedItems,
  setSelectedItems,
  contents,
  afterActionCallback,
}) => {
  const TABS = {
    전체: 1,
    선별대기: 3,
    선별완료: 4,
  };

  const buttonsForAllContents = {
    cam: {
      seg: {
        [TABS.선별대기]: (
          <>
            <VldMaskRgs set_id={set_id} />
            <VldSegAuto set_id={set_id} afterActionCallback={afterActionCallback} />
          </>
        ),
        [TABS.선별완료]: <DownloadVldResult />,
      },
      det: {
        [TABS.선별대기]: <VldDetAuto set_id={set_id} afterActionCallback={afterActionCallback} />,
        // 자동 추론 데이터 분포 이미지 다운로드 기능은 없어도 되는가?
        // [TABS.선별완료] :(<DownloadVldResult />),
      },
    },
    rad: {
      seg: {
        [TABS.전체]: (
          <>
            <VldPropList />
            <VldMapUpload />
            <DownloadGps count={contents.count} />
            <DownloadOrigAll count={contents.count} />
          </>
        ),
      },
    },
  };

  const buttonsForSelectedContents = {
    cam: {
      [TABS.선별대기]: {
        thumb: (
          <>
            <VldInf selectedItems={selectedItems} contents={contents.results} />
            <VldAddTag
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              tag={"val"}
            />
            <SetVldTag selectedItems={selectedItems} tag={"val"} />
            <VldReset selectedItems={selectedItems} />
          </>
        ),
        table: (
          <>
            <VldInf selectedItems={selectedItems} contents={contents.results} />
            <VldReset selectedItems={selectedItems} />
          </>
        ),
      },
      [TABS.선별완료]: {
        thumb: <VldReset selectedItems={selectedItems} />,
        table: <VldReset selectedItems={selectedItems} />,
      },
    },
    rad: {
      [TABS.전체]: {
        thumb: <DownloadOrig selectedItems={selectedItems} />,
        table: <DownloadOrig selectedItems={selectedItems} />,
      },
      [TABS.선별대기]: {
        thumb: (
          <>
            <VldAddTag
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              tag={"val"}
            />
            <SetVldTag selectedItems={selectedItems} tag={"val"} />
            <VldReset selectedItems={selectedItems} />
          </>
        ),
        table: (
          <>
            <VldReset selectedItems={selectedItems} />
          </>
        ),
      },
      [TABS.선별완료]: {
        thumb: (
          <>
            <VldReset selectedItems={selectedItems} />
          </>
        ),
        table: (
          <>
            <VldReset selectedItems={selectedItems} />
          </>
        ),
      },
    },
  };
  return (
    <>
      {buttonsForAllContents[sensor_code]?.[gt_code]?.[tab]}
      <SelectCnt count={selectedItems.length} />
      {buttonsForSelectedContents[sensor_code]?.[tab]?.[view]}
      <VldDelCheck contents={contents.results} selectedItems={selectedItems} />
    </>
  );
};

const renderActionsNoPermit = ({ sensor_code, gt_code, tab }) => {
  const TABS = {
    전체: 1,
    선별대기: 3,
    선별완료: 4,
  };

  const buttons = {
    cam: {
      seg: {
        [TABS.선별완료]: <DownloadVldResult />,
      },
    },
  };

  return buttons[sensor_code]?.[gt_code]?.[tab];
};

const automationInfo = {
  cam: {
    seg: {
      data_key: "cam_seg_validating",
      url: (set_id) => `vld/cam/seg/datas/${set_id}/auto/check/`,
      message: "자동 선별이 시작되었습니다",
      tabs: ["1", "3"],
    },
    det: {
      data_key: "det_validating",
      url: (set_id) => `vld/cam/det/datas/${set_id}/auto/check/`,
      message: "자동 추론이 시작되었습니다",
      tabs: ["1", "3"],
    },
  },
  get(sensor_code, gt_code) {
    return this[sensor_code]?.[gt_code];
  },
};

const AutomationLoading = forwardRef(function AutomationLoading(props, ref) {
  const { url, data_key, message, afterAutomationFinish } = props;
  const [token, update] = useUpdate();

  // 자동 선별 진행 중 표시
  const [showOngoingMessage, setShowOngoingMessage] = useState(false);
  const ongoingTimer = useRef(null);

  useEffect(() => {
    const checkOngoing = async () => {
      const { data } = await axiosInstance.get(url);

      const showMessage = data[data_key];
      setShowOngoingMessage(showMessage);
      if (showMessage) {
        ongoingTimer.current = setTimeout(checkOngoing, 3000);
      } else if (ongoingTimer.current) {
        afterAutomationFinish();
      }
    };

    checkOngoing();
    return () => {
      setShowOngoingMessage(false);
      clearTimeout(ongoingTimer.current);
    };
  }, [url, data_key, token]);

  useImperativeHandle(ref, () => {
    return {
      checkAutomation() {
        update();
      },
    };
  });

  if (!showOngoingMessage) return null;

  return (
    <div className="absolute left-[50%] top-[50%] z-10 translate-x-[-50%] translate-y-[-50%] rounded-[20px] bg-white bg-opacity-80 p-[16px]">
      {message}
      <Loader styleType={"autoVld"} />
    </div>
  );
});

export default VldDataPage;
