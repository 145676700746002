import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useSelectTableRows, useShiftDownEvent } from "../../../lib/hooks";
import { useDynamicRouting } from "../../../lib/context/useDynamicRouting";
import { formatAmountComma } from "../../../lib/utils";
import { Empty, Loader, LoadFail } from "../../atoms/Status";
import {
  SelectButton,
  SelectCheckbox,
  SelectBox,
  SelectItem,
  SelectUl,
} from "../../atoms/SelectBox";

const FilterItem = ({ data, head, status, statusCodeData }) => {
  let ulRef;
  const { queryParams, delQueryParams, setQueryParams } = useDynamicRouting();
  const values = queryParams[head.col]?.split(",");
  const [selectedObj, setSelectedObj] = useState({
    selectedItems: values || [],
    lastSelectedItem: null,
  });
  const { handleClickRow, handleClickHeaderCheckbox } = useSelectTableRows(
    head.icon === "IC_datetime_s" || head.icon === "IC_time_s"
      ? data?.map(([v]) => v.replace(/ /g, ""))
      : data?.map(([v]) => v),
    setSelectedObj
  );
  const allChecked = data?.length && data?.length === selectedObj.selectedItems?.length;
  const [isShiftDown, useShiftDown] = useShiftDownEvent();
  useShiftDown();

  useEffect(() => {
    const ref = ulRef;
    const handleSelectStart = (e) => {
      // shift시 select 방지
      if (isShiftDown) e.preventDefault();
    };

    ref.addEventListener("selectstart", handleSelectStart);
    return () => {
      ref.removeEventListener("selectstart", handleSelectStart);
    };
  }, [isShiftDown]);

  const handleClick = () => {
    if (selectedObj.selectedItems?.length === 0) {
      // 선택된 아이템이 없으면 적용된 필터링 삭제
      delQueryParams([head.col]);
    } else {
      // 선택된 아이템이 있으면 필터링 항목 업데이트
      setQueryParams({
        [head.col]: selectedObj.selectedItems.join(),
        page: 1,
      });
    }
    setSelectedObj({
      selectedItems: [],
      lastSelectedItem: null,
    });
  };

  return (
    <div
      className={clsx(queryParams.view === "thumb" ? "fixed" : "relative")}
      ref={(el) => (ulRef = el)}
    >
      <SelectBox
        thumbHead={queryParams.view === "thumb"}
        open
        styleType="filter"
        cn={status === "loading" && "h-[140px]"}
      >
        {status === "loading" ? (
          <Loader styleType="filter" />
        ) : status === "hasValue" ? (
          data?.length > 0 ? (
            <>
              <SelectUl>
                <SelectItem
                  item={{ ko: "전체" }}
                  styleType="list"
                  alignRight={head.name.slice(-4) === "_cnt" || head.type === "num"}
                >
                  <SelectCheckbox
                    onChange={(e) => handleClickHeaderCheckbox(e, allChecked)}
                    checked={allChecked}
                    value={allChecked}
                    styleType="list"
                  />
                </SelectItem>
                {data.map(([v, b], index) => (
                  <SelectItem
                    key={index}
                    index={index}
                    item={
                      v === "true"
                        ? "O"
                        : v === "false"
                          ? "X"
                          : v === "none"
                            ? "(비어있음)"
                            : head.col === "status" ||
                                head.col === "sensorType" ||
                                head.col === "vldType"
                              ? statusCodeData?.find((x) => x.value === v)?.ko || `STATUS_CODE_${v}`
                              : head.icon === "IC_datetime_s" || head.icon === "IC_time_s"
                                ? `${v}'`
                                : head.name.slice(-4) === "_cnt" || head.type === "num"
                                  ? formatAmountComma(v)
                                  : v
                    }
                    pagination
                    styleType="list"
                    bool={b}
                    alignRight={head.name.slice(-4) === "_cnt" || head.type === "num"}
                  >
                    <SelectCheckbox
                      onChange={() =>
                        handleClickRow(
                          head.icon === "IC_datetime_s" || head.icon === "IC_time_s"
                            ? v.replace(/ /g, "")
                            : v,
                          isShiftDown
                        )
                      }
                      checked={
                        selectedObj.selectedItems?.includes(
                          head.icon === "IC_datetime_s" || head.icon === "IC_time_s"
                            ? v.replace(/ /g, "")
                            : v
                        )
                          ? true
                          : false
                      }
                      value={
                        head.icon === "IC_datetime_s" || head.icon === "IC_time_s"
                          ? v.replace(/ /g, "")
                          : v
                      }
                      bool={b}
                    />
                  </SelectItem>
                ))}
              </SelectUl>
              <SelectButton onClick={handleClick} />
            </>
          ) : (
            <div className="flex min-h-[140px] items-center">
              <Empty />
            </div>
          )
        ) : (
          <div className="flex min-h-[140px] items-center">
            <LoadFail failType="실패" />
          </div>
        )}
      </SelectBox>
    </div>
  );
};

export default FilterItem;
