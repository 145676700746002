import { IMG_TYPE_LIST } from "../../lib/constants/constant";

export const CHECK_CLCT_COLS = {
  cam_seg: {
    tab: [
      "data",
      "path",
      "port",
      "berth",
      "vessel",
      "date",
      "cond_cnts",
      "tab_cnts",
      "cam_seg_pri",
      "cam_seg_vld_cmp_t",
      /*'cam_seg_revalidating',*/ "module",
    ],
    hide: ["cond_cnts", "module"],
  },
  cam_det: {
    tab: [
      "data",
      "path",
      "port",
      "berth",
      "vessel",
      "date",
      "cond_cnts",
      "tab_cnts",
      "det_pri",
      "ids_num",
      "person_obj_num",
      "vessel_obj_num",
      "buoy_obj_num",
      "smoke_obj_num",
      "det_vld_cmp_t",
      /*'cam_det_revalidating',*/ "module",
    ],
    hide: ["cond_cnts", "vessel_obj_num", "buoy_obj_num", "smoke_obj_num", "module"],
  },
  rad_seg: {
    tab: [
      "data",
      "path",
      "port",
      "berth",
      "vessel",
      "date",
      "tab_cnts",
      "rad_seg_pri",
      "rad_seg_vld_cmp_t",
      /*'rad_seg_revalidating',*/ "module",
    ],
  },
};

export const CHECK_DATA_COLS = {
  cam_seg: {
    // 전체(1) - 검수통과(8), 작업회수(10), 검수자 변경(11) 같은 컬럼값 사용, 옵션 변경시 추가 확인 필요
    tab1: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "difficulty",
      "unit_price",
      "worker",
      "checker",
      "change_req_checker",
      "label_v",
      "set_name",
      "status",
      "dist_date",
      "dist_rep",
      "orig_dwl_t",
      "gt_ul_t",
      "ul_checker",
      "check_req_t",
      "errs_exist",
      "check_insp_t",
      "check_t",
      "payment_date",
      "check_date",
      "cncl_req_t",
      "cncl_cuz",
      "cncl_t",
      "snow",
      "rain",
      "fog",
      "light",
      "backlight",
      "night",
      "smoke",
    ],
    // 배분대기(2)
    tab2: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "difficulty",
      "unit_price",
      "worker",
      "checker",
      "label_v",
      "set_name",
      "status",
      "dist_rep",
      "cncl_req_t",
      "cncl_cuz",
      "cncl_t",
      "snow",
      "rain",
      "fog",
      "light",
      "backlight",
      "night",
      "smoke",
    ],
    // 작업대기(4)
    tab4: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "difficulty",
      "unit_price",
      "worker",
      "checker",
      "change_req_checker",
      "label_v",
      "set_name",
      "status",
      "dist_date",
      "dist_rep",
      "orig_dwl_t",
      "gt_ul_t",
      "cncl_req_t",
      "cncl_cuz",
      "cncl_t",
      "snow",
      "rain",
      "fog",
      "light",
      "backlight",
      "night",
      "smoke",
    ],
    // 작업중(5)
    tab5: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "difficulty",
      "unit_price",
      "worker",
      "checker",
      "change_req_checker",
      "label_v",
      "set_name",
      "status",
      "orig_dwl_t",
      "gt_ul_t",
      "check_req_t",
      "cncl_req_t",
      "cncl_cuz",
      "cncl_t",
      "snow",
      "rain",
      "fog",
      "light",
      "backlight",
      "night",
      "smoke",
    ],
    // 검수대기(6)
    tab6: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "difficulty",
      "unit_price",
      "worker",
      "checker",
      "change_req_checker",
      "label_v",
      "set_name",
      "status",
      "orig_dwl_t",
      "gt_ul_t",
      "check_req_t",
      "cncl_req_t",
      "cncl_cuz",
      "cncl_t",
      "snow",
      "rain",
      "fog",
      "light",
      "backlight",
      "night",
      "smoke",
    ],
    // 검수반려(7)
    tab7: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "difficulty",
      "unit_price",
      "worker",
      "checker",
      "change_req_checker",
      "label_v",
      "set_name",
      "status",
      "orig_dwl_t",
      "gt_ul_t",
      "check_req_t",
      "check_t",
      "cncl_req_t",
      "cncl_cuz",
      "cncl_t",
      "snow",
      "rain",
      "fog",
      "light",
      "backlight",
      "night",
      "smoke",
    ],
    // 검수통과(8)
    tab8: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "difficulty",
      "unit_price",
      "worker",
      "checker",
      "change_req_checker",
      "label_v",
      "set_name",
      "status",
      "orig_dwl_t",
      "gt_ul_t",
      "ul_checker",
      "check_req_t",
      "errs_exist",
      "check_insp_t",
      "check_t",
      "payment_date",
      "check_date",
      "cncl_req_t",
      "cncl_cuz",
      "cncl_t",
      "snow",
      "rain",
      "fog",
      "light",
      "backlight",
      "night",
      "smoke",
    ],
    // 작업회수(10)
    tab10: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "difficulty",
      "unit_price",
      "worker",
      "checker",
      "change_req_checker",
      "label_v",
      "set_name",
      "status",
      "orig_dwl_t",
      "gt_ul_t",
      "ul_checker",
      "check_req_t",
      "check_t",
      "payment_date",
      "check_date",
      "cncl_req_t",
      "cncl_cuz",
      "cncl_t",
      "snow",
      "rain",
      "fog",
      "light",
      "backlight",
      "night",
      "smoke",
    ],
    // 검수자 변경(11)
    tab11: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "difficulty",
      "unit_price",
      "worker",
      "checker",
      "change_req_checker",
      "label_v",
      "set_name",
      "status",
      "orig_dwl_t",
      "gt_ul_t",
      "ul_checker",
      "check_req_t",
      "check_t",
      "payment_date",
      "check_date",
      "cncl_req_t",
      "cncl_cuz",
      "cncl_t",
      "snow",
      "rain",
      "fog",
      "light",
      "backlight",
      "night",
      "smoke",
    ],
    hide: {
      table: {
        tab1: [
          "snow",
          "rain",
          "fog",
          "light",
          "backlight",
          "night",
          "smoke",
          "dist_date",
          "dist_rep",
          "errs_exist",
          "check_insp_t",
          "clct_t",
          "label_v",
        ],
        tab2: [
          "snow",
          "rain",
          "fog",
          "light",
          "backlight",
          "night",
          "smoke",
          "clct_t",
          "status",
          "cncl_req_t",
        ],
        tab4: [
          "snow",
          "rain",
          "fog",
          "light",
          "backlight",
          "night",
          "smoke",
          "clct_t",
          "change_req_checker",
          "label_v",
          "status",
          "gt_ul_t",
          "cncl_req_t",
          "cncl_t",
          "cncl_cuz",
        ],
        tab5: [
          "snow",
          "rain",
          "fog",
          "light",
          "backlight",
          "night",
          "smoke",
          "clct_t",
          "change_req_checker",
          "label_v",
          "orig_dwl_t",
          "check_req_t",
          "cncl_req_t",
          "cncl_t",
          "cncl_cuz",
        ],
        tab6: [
          "snow",
          "rain",
          "fog",
          "light",
          "backlight",
          "night",
          "smoke",
          "clct_t",
          "change_req_checker",
          "label_v",
          "orig_dwl_t",
          "check_req_t",
          "cncl_req_t",
          "cncl_t",
          "cncl_cuz",
        ],
        tab7: [
          "snow",
          "rain",
          "fog",
          "light",
          "backlight",
          "night",
          "smoke",
          "clct_t",
          "change_req_checker",
          "label_v",
          "status",
          "orig_dwl_t",
          "cncl_req_t",
          "cncl_t",
          "cncl_cuz",
        ],
        tab8: [
          "snow",
          "rain",
          "fog",
          "light",
          "backlight",
          "night",
          "smoke",
          "clct_t",
          "change_req_checker",
          "label_v",
          "status",
          "orig_dwl_t",
          "check_req_t",
          "cncl_req_t",
          "cncl_t",
          "cncl_cuz",
        ],
        tab10: [
          "snow",
          "rain",
          "fog",
          "light",
          "backlight",
          "night",
          "smoke",
          "clct_t",
          "change_req_checker",
          "label_v",
          "status",
          "orig_dwl_t",
          "gt_ul_t",
          "ul_checker",
          "check_req_t",
          "check_t",
          "payment_date",
          "check_date",
          "cncl_t",
        ],
        tab11: [
          "snow",
          "rain",
          "fog",
          "light",
          "backlight",
          "night",
          "smoke",
          "clct_t",
          "label_v",
          "status",
          "orig_dwl_t",
          "gt_ul_t",
          "ul_checker",
          "check_req_t",
          "check_t",
          "payment_date",
          "check_date",
          "cncl_req_t",
          "cncl_cuz",
          "cncl_t",
        ],
      },
      thumb: {
        tab1: [
          "rework_cnt",
          "filename",
          "dist_date",
          "dist_rep",
          "errs_exist",
          "check_insp_t",
          "clct_t",
          "label_v",
        ],
        tab2: ["rework_cnt", "filename", "clct_t", "status", "cncl_req_t"],
        tab4: [
          "rework_cnt",
          "filename",
          "clct_t",
          "change_req_checker",
          "label_v",
          "status",
          "gt_ul_t",
          "check_req_t",
          "cncl_req_t",
          "cncl_t",
          "cncl_cuz",
        ],
        tab5: [
          "rework_cnt",
          "filename",
          "clct_t",
          "change_req_checker",
          "label_v",
          "orig_dwl_t",
          "check_req_t",
          "cncl_req_t",
          "cncl_t",
          "cncl_cuz",
        ],
        tab6: [
          "rework_cnt",
          "filename",
          "clct_t",
          "change_req_checker",
          "label_v",
          "orig_dwl_t",
          "check_req_t",
          "cncl_req_t",
          "cncl_t",
          "cncl_cuz",
        ],
        tab7: [
          "rework_cnt",
          "filename",
          "clct_t",
          "change_req_checker",
          "label_v",
          "status",
          "orig_dwl_t",
          "cncl_req_t",
          "cncl_t",
          "cncl_cuz",
        ],
        tab8: [
          "rework_cnt",
          "filename",
          "clct_t",
          "change_req_checker",
          "label_v",
          "status",
          "orig_dwl_t",
          "check_req_t",
          "cncl_req_t",
          "cncl_t",
          "cncl_cuz",
        ],
        tab10: [
          "rework_cnt",
          "filename",
          "clct_t",
          "change_req_checker",
          "label_v",
          "status",
          "orig_dwl_t",
          "gt_ul_t",
          "ul_checker",
          "check_req_t",
          "check_t",
          "payment_date",
          "check_date",
          "cncl_t",
        ],
        tab11: [
          "rework_cnt",
          "filename",
          "clct_t",
          "label_v",
          "status",
          "orig_dwl_t",
          "gt_ul_t",
          "ul_checker",
          "check_req_t",
          "check_t",
          "payment_date",
          "check_date",
          "cncl_req_t",
          "cncl_cuz",
          "cncl_t",
        ],
      },
    },
  },
  cam_det: {
    // 전체(1) - 검수통과(8), 작업회수(10), 검수자 변경(11) 같은 컬럼값 사용, 옵션 변경시 추가 확인 필요
    tab1: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "difficulty",
      "snow",
      "rain",
      "fog",
      "light",
      "backlight",
      "night",
      "smoke",
      "person_price",
      "vessel_price",
      "buoy_price",
      "smoke_price",
      "worker",
      "checker",
      "change_req_checker",
      "label_v",
      "tracked",
      "set_name",
      "status",
      "dist_date",
      "dist_rep",
      "orig_dwl_t",
      "gt_ul_t",
      "ul_checker",
      "check_req_t",
      "check_t",
      "payment_date",
      "check_date",
      "cncl_req_t",
      "cncl_cuz",
      "cncl_t",
    ],
    // 배분대기(2)
    tab2: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "difficulty",
      "snow",
      "rain",
      "fog",
      "light",
      "backlight",
      "night",
      "smoke",
      "person_price",
      "vessel_price",
      "buoy_price",
      "smoke_price",
      "worker",
      "checker",
      "change_req_checker",
      "label_v",
      "tracked",
      "set_name",
      "dist_rep",
      "status",
      "cncl_req_t",
      "cncl_cuz",
      "cncl_t",
    ],
    // 작업대기(4)
    tab4: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "difficulty",
      "snow",
      "rain",
      "fog",
      "light",
      "backlight",
      "night",
      "smoke",
      "person_price",
      "vessel_price",
      "buoy_price",
      "smoke_price",
      "worker",
      "checker",
      "change_req_checker",
      "label_v",
      "tracked",
      "set_name",
      "status",
      "dist_date",
      "dist_rep",
      "orig_dwl_t",
      "gt_ul_t",
      "cncl_req_t",
      "cncl_cuz",
      "cncl_t",
    ],
    // 작업중(5)
    tab5: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "difficulty",
      "snow",
      "rain",
      "fog",
      "light",
      "backlight",
      "night",
      "smoke",
      "person_price",
      "vessel_price",
      "buoy_price",
      "smoke_price",
      "worker",
      "checker",
      "change_req_checker",
      "label_v",
      "tracked",
      "set_name",
      "status",
      "orig_dwl_t",
      "gt_ul_t",
      "cncl_req_t",
      "cncl_cuz",
      "cncl_t",
    ],
    // 검수대기(6)
    tab6: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "difficulty",
      "snow",
      "rain",
      "fog",
      "light",
      "backlight",
      "night",
      "smoke",
      "person_price",
      "vessel_price",
      "buoy_price",
      "smoke_price",
      "worker",
      "checker",
      "change_req_checker",
      "label_v",
      "tracked",
      "set_name",
      "status",
      "orig_dwl_t",
      "gt_ul_t",
      "check_req_t",
      "cncl_req_t",
      "cncl_cuz",
      "cncl_t",
    ],
    // 검수반려(7)
    tab7: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "difficulty",
      "snow",
      "rain",
      "fog",
      "light",
      "backlight",
      "night",
      "smoke",
      "person_price",
      "vessel_price",
      "buoy_price",
      "smoke_price",
      "worker",
      "checker",
      "change_req_checker",
      "label_v",
      "tracked",
      "set_name",
      "status",
      "orig_dwl_t",
      "gt_ul_t",
      "check_req_t",
      "check_t",
      "cncl_req_t",
      "cncl_cuz",
      "cncl_t",
    ],
    // 검수통과(8)
    tab8: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "difficulty",
      "snow",
      "rain",
      "fog",
      "light",
      "backlight",
      "night",
      "smoke",
      "person_price",
      "vessel_price",
      "buoy_price",
      "smoke_price",
      "worker",
      "checker",
      "change_req_checker",
      "label_v",
      "tracked",
      "set_name",
      "status",
      "orig_dwl_t",
      "gt_ul_t",
      "ul_checker",
      "check_req_t",
      "check_t",
      "payment_date",
      "check_date",
      "cncl_req_t",
      "cncl_cuz",
      "cncl_t",
    ],
    // 작업회수(10)
    tab10: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "difficulty",
      "snow",
      "rain",
      "fog",
      "light",
      "backlight",
      "night",
      "smoke",
      "person_price",
      "vessel_price",
      "buoy_price",
      "smoke_price",
      "worker",
      "checker",
      "change_req_checker",
      "label_v",
      "tracked",
      "set_name",
      "status",
      "orig_dwl_t",
      "gt_ul_t",
      "ul_checker",
      "check_t",
      "payment_date",
      "check_date",
      "cncl_req_t",
      "cncl_cuz",
      "cncl_t",
    ],
    // 검수자 변경(11)
    tab11: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "difficulty",
      "snow",
      "rain",
      "fog",
      "light",
      "backlight",
      "night",
      "smoke",
      "person_price",
      "vessel_price",
      "buoy_price",
      "smoke_price",
      "worker",
      "checker",
      "change_req_checker",
      "label_v",
      "tracked",
      "set_name",
      "status",
      "orig_dwl_t",
      "gt_ul_t",
      "ul_checker",
      "check_t",
      "payment_date",
      "check_date",
      "cncl_req_t",
      "cncl_cuz",
      "cncl_t",
    ],
    hide: {
      table: {
        tab1: [
          "snow",
          "rain",
          "fog",
          "light",
          "backlight",
          "night",
          // 'smoke',
          "vessel_price",
          "buoy_price",
          "smoke_price",
          "clct_t",
          "label_v",
          "tracked",
          "dist_date",
          "dist_rep",
          "ul_checker",
          "check_t",
        ],
        tab2: [
          "snow",
          "rain",
          "fog",
          "light",
          "backlight",
          "night",
          // 'smoke',
          "vessel_price",
          "buoy_price",
          "smoke_price",
          "clct_t",
          "change_req_checker",
          "status",
          "cncl_req_t",
        ],
        tab4: [
          "snow",
          "rain",
          "fog",
          "light",
          "backlight",
          "night",
          // 'smoke',
          "vessel_price",
          "buoy_price",
          "smoke_price",
          "clct_t",
          "change_req_checker",
          "label_v",
          "tracked",
          "status",
          "gt_ul_t",
          "cncl_req_t",
          "cncl_t",
          "cncl_cuz",
        ],
        tab5: [
          "snow",
          "rain",
          "fog",
          "light",
          "backlight",
          "night",
          // 'smoke',
          "vessel_price",
          "buoy_price",
          "smoke_price",
          "clct_t",
          "change_req_checker",
          "label_v",
          "tracked",
          "orig_dwl_t",
          "cncl_req_t",
          "cncl_t",
          "cncl_cuz",
        ],
        tab6: [
          "snow",
          "rain",
          "fog",
          "light",
          "backlight",
          "night",
          // 'smoke',
          "vessel_price",
          "buoy_price",
          "smoke_price",
          "clct_t",
          "change_req_checker",
          "label_v",
          "tracked",
          "orig_dwl_t",
          "check_req_t",
          "cncl_req_t",
          "cncl_t",
          "cncl_cuz",
        ],
        tab7: [
          "snow",
          "rain",
          "fog",
          "light",
          "backlight",
          "night",
          // 'smoke',
          "vessel_price",
          "buoy_price",
          "smoke_price",
          "clct_t",
          "change_req_checker",
          "label_v",
          "tracked",
          "status",
          "orig_dwl_t",
          "cncl_req_t",
          "cncl_t",
          "cncl_cuz",
        ],
        tab8: [
          "snow",
          "rain",
          "fog",
          "light",
          "backlight",
          "night",
          // 'smoke',
          "vessel_price",
          "buoy_price",
          "smoke_price",
          "clct_t",
          "change_req_checker",
          "label_v",
          "tracked",
          "status",
          "orig_dwl_t",
          "check_req_t",
          "cncl_req_t",
          "cncl_cuz",
          "cncl_t",
        ],
        tab10: [
          "snow",
          "rain",
          "fog",
          "light",
          "backlight",
          "night",
          // 'smoke',
          "vessel_price",
          "buoy_price",
          "smoke_price",
          "clct_t",
          "change_req_checker",
          "label_v",
          "tracked",
          "status",
          "orig_dwl_t",
          "gt_ul_t",
          "ul_checker",
          "check_t",
          "payment_date",
          "check_date",
          "cncl_t",
        ],
        tab11: [
          "snow",
          "rain",
          "fog",
          "light",
          "backlight",
          "night",
          // 'smoke',
          "vessel_price",
          "buoy_price",
          "smoke_price",
          "clct_t",
          "label_v",
          "tracked",
          "status",
          "orig_dwl_t",
          "gt_ul_t",
          "ul_checker",
          "check_t",
          "payment_date",
          "check_date",
          "cncl_req_t",
          "cncl_cuz",
          "cncl_t",
        ],
      },
      thumb: {
        tab1: [
          "rework_cnt",
          "filename",
          "snow",
          "rain",
          "fog",
          "light",
          "backlight",
          "night",
          // 'smoke',
          "vessel_price",
          "buoy_price",
          "smoke_price",
          "clct_t",
          "label_v",
          "tracked",
          "dist_date",
          "dist_rep",
          "ul_checker",
          "check_t",
        ],
        tab2: [
          "rework_cnt",
          "filename",
          "snow",
          "rain",
          "fog",
          "light",
          "backlight",
          "night",
          // 'smoke',
          "vessel_price",
          "buoy_price",
          "smoke_price",
          "clct_t",
          "change_req_checker",
          "status",
          "cncl_req_t",
        ],
        tab4: [
          "rework_cnt",
          "filename",
          "snow",
          "rain",
          "fog",
          "light",
          "backlight",
          "night",
          // 'smoke',
          "vessel_price",
          "buoy_price",
          "smoke_price",
          "clct_t",
          "change_req_checker",
          "label_v",
          "tracked",
          "status",
          "gt_ul_t",
          "cncl_req_t",
          "cncl_t",
          "cncl_cuz",
        ],
        tab5: [
          "rework_cnt",
          "filename",
          "snow",
          "rain",
          "fog",
          "light",
          "backlight",
          "night",
          // 'smoke',
          "vessel_price",
          "buoy_price",
          "smoke_price",
          "clct_t",
          "change_req_checker",
          "label_v",
          "tracked",
          "orig_dwl_t",
          "cncl_req_t",
          "cncl_t",
          "cncl_cuz",
        ],
        tab6: [
          "rework_cnt",
          "filename",
          "snow",
          "rain",
          "fog",
          "light",
          "backlight",
          "night",
          // 'smoke',
          "vessel_price",
          "buoy_price",
          "smoke_price",
          "clct_t",
          "change_req_checker",
          "label_v",
          "orig_dwl_t",
          "check_req_t",
          "cncl_req_t",
          "cncl_t",
          "cncl_cuz",
        ],
        tab7: [
          "rework_cnt",
          "filename",
          "snow",
          "rain",
          "fog",
          "light",
          "backlight",
          "night",
          // 'smoke',
          "vessel_price",
          "buoy_price",
          "smoke_price",
          "clct_t",
          "change_req_checker",
          "label_v",
          "status",
          "orig_dwl_t",
          "cncl_req_t",
          "cncl_t",
          "cncl_cuz",
        ],
        tab8: [
          "rework_cnt",
          "filename",
          "snow",
          "rain",
          "fog",
          "light",
          "backlight",
          "night",
          // 'smoke',
          "vessel_price",
          "buoy_price",
          "smoke_price",
          "clct_t",
          "change_req_checker",
          "label_v",
          "tracked",
          "status",
          "orig_dwl_t",
          "gt_ul_t",
          "ul_checker",
          "check_t",
          "cncl_req_t",
          "cncl_cuz",
          "cncl_t",
        ],
        tab10: [
          "rework_cnt",
          "filename",
          "snow",
          "rain",
          "fog",
          "light",
          "backlight",
          "night",
          // 'smoke',
          "vessel_price",
          "buoy_price",
          "smoke_price",
          "clct_t",
          "change_req_checker",
          "label_v",
          "tracked",
          "status",
          "orig_dwl_t",
          "gt_ul_t",
          "ul_checker",
          "check_t",
          "payment_date",
          "check_date",
          "cncl_t",
        ],
        tab11: [
          "rework_cnt",
          "filename",
          "snow",
          "rain",
          "fog",
          "light",
          "backlight",
          "night",
          // 'smoke',
          "vessel_price",
          "buoy_price",
          "smoke_price",
          "clct_t",
          "label_v",
          "tracked",
          "status",
          "orig_dwl_t",
          "gt_ul_t",
          "ul_checker",
          "check_t",
          "payment_date",
          "check_date",
          "cncl_req_t",
          "cncl_cuz",
          "cncl_t",
        ],
      },
    },
  },
  rad_seg: {
    // 전체(1) - 검수통과(8), 작업회수(10), 검수자 변경(11) 같은 컬럼값 사용, 옵션 변경시 추가 확인 필요
    tab1: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "difficulty",
      "unit_price",
      "worker",
      "checker",
      "change_req_checker",
      "label_v",
      "set_name",
      "status",
      "dist_date",
      "dist_rep",
      "orig_dwl_t",
      "gt_ul_t",
      "ul_checker",
      "check_t",
      "payment_date",
      "check_date",
      "check_req_t",
      "cncl_req_t",
      "cncl_cuz",
      "cncl_t",
    ],
    // 배분대기(2)
    tab2: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "difficulty",
      "unit_price",
      "worker",
      "checker",
      "change_req_checker",
      "label_v",
      "set_name",
      "status",
      "dist_rep",
      "cncl_req_t",
      "cncl_cuz",
      "cncl_t",
    ],
    // 작업대기(4)
    tab4: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "difficulty",
      "unit_price",
      "worker",
      "checker",
      "change_req_checker",
      "label_v",
      "set_name",
      "status",
      "dist_date",
      "dist_rep",
      "orig_dwl_t",
      "gt_ul_t",
      "cncl_req_t",
      "cncl_cuz",
      "cncl_t",
    ],
    // 작업중(5)
    tab5: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "difficulty",
      "unit_price",
      "worker",
      "checker",
      "change_req_checker",
      "label_v",
      "set_name",
      "status",
      "orig_dwl_t",
      "gt_ul_t",
      "check_req_t",
      "cncl_req_t",
      "cncl_cuz",
      "cncl_t",
    ],
    // 검수대기(6)
    tab6: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "difficulty",
      "unit_price",
      "worker",
      "checker",
      "change_req_checker",
      "label_v",
      "set_name",
      "status",
      "orig_dwl_t",
      "gt_ul_t",
      "check_req_t",
      "cncl_req_t",
      "cncl_cuz",
      "cncl_t",
    ],
    // 검수반려(7)
    tab7: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "difficulty",
      "unit_price",
      "worker",
      "checker",
      "change_req_checker",
      "label_v",
      "set_name",
      "status",
      "orig_dwl_t",
      "gt_ul_t",
      "check_req_t",
      "cncl_req_t",
      "cncl_cuz",
      "cncl_t",
    ],
    // 검수 통과(8)
    tab8: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "difficulty",
      "unit_price",
      "worker",
      "checker",
      "change_req_checker",
      "label_v",
      "set_name",
      "status",
      "orig_dwl_t",
      "gt_ul_t",
      "ul_checker",
      "check_t",
      "payment_date",
      "check_date",
      "check_req_t",
      "cncl_req_t",
      "cncl_cuz",
      "cncl_t",
    ],
    // 작업 회수(10)
    tab10: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "difficulty",
      "unit_price",
      "worker",
      "checker",
      "change_req_checker",
      "label_v",
      "set_name",
      "status",
      "orig_dwl_t",
      "gt_ul_t",
      "ul_checker",
      "check_t",
      "payment_date",
      "check_date",
      "check_req_t",
      "cncl_req_t",
      "cncl_cuz",
      "cncl_t",
    ],
    // 검수자 변경(11)
    tab11: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "difficulty",
      "unit_price",
      "worker",
      "checker",
      "change_req_checker",
      "label_v",
      "set_name",
      "status",
      "orig_dwl_t",
      "gt_ul_t",
      "ul_checker",
      "check_t",
      "payment_date",
      "check_date",
      "check_req_t",
      "cncl_req_t",
      "cncl_cuz",
      "cncl_t",
    ],
    hide: {
      table: {
        tab1: ["clct_t", "label_v", "dist_date", "dist_rep"],
        tab2: ["clct_t", "change_req_checker", "status", "cncl_req_t"],
        tab4: [
          "clct_t",
          "change_req_checker",
          "label_v",
          "status",
          "gt_ul_t",
          "cncl_req_t",
          "cncl_cuz",
          "cncl_t",
        ],
        tab5: [
          "clct_t",
          "change_req_checker",
          "label_v",
          "orig_dwl_t",
          "check_req_t",
          "cncl_req_t",
          "cncl_cuz",
          "cncl_t",
        ],
        tab6: [
          "clct_t",
          "change_req_checker",
          "label_v",
          "orig_dwl_t",
          "check_req_t",
          "cncl_req_t",
          "cncl_cuz",
          "cncl_t",
        ],
        tab7: [
          "clct_t",
          "change_req_checker",
          "label_v",
          "orig_dwl_t",
          "cncl_req_t",
          "cncl_cuz",
          "cncl_t",
        ],
        tab8: [
          "clct_t",
          "change_req_checker",
          "label_v",
          "status",
          "orig_dwl_t",
          "check_req_t",
          "cncl_req_t",
          "cncl_cuz",
          "cncl_t",
        ],
        tab10: [
          "clct_t",
          "change_req_checker",
          "label_v",
          "status",
          "orig_dwl_t",
          "gt_ul_t",
          "check_req_t",
          "ul_checker",
          "check_t",
          "payment_date",
          "check_date",

          "cncl_t",
        ],
        tab11: [
          "clct_t",
          "label_v",
          "status",
          "orig_dwl_t",
          "gt_ul_t",
          "check_req_t",
          "ul_checker",
          "check_t",
          "payment_date",
          "check_date",

          "cncl_req_t",
          "cncl_cuz",
          "cncl_t",
        ],
      },
      thumb: {
        tab1: ["rework_cnt", "filename", "dist_date", "dist_rep", "clct_t", "label_v"],
        tab2: ["rework_cnt", "filename", "clct_t", "change_req_checker", "status", "cncl_req_t"],
        tab4: [
          "rework_cnt",
          "filename",
          "clct_t",
          "change_req_checker",
          "label_v",
          "status",
          "gt_ul_t",
          "check_req_t",
          "cncl_req_t",
          "cncl_cuz",
          "cncl_t",
        ],
        tab5: [
          "rework_cnt",
          "filename",
          "clct_t",
          "change_req_checker",
          "label_v",
          "orig_dwl_t",
          "check_req_t",
          "cncl_req_t",
          "cncl_cuz",
          "cncl_t",
        ],
        tab6: [
          "rework_cnt",
          "filename",
          "clct_t",
          "change_req_checker",
          "label_v",
          "orig_dwl_t",
          "check_req_t",
          "cncl_req_t",
          "cncl_cuz",
          "cncl_t",
        ],
        tab7: [
          "rework_cnt",
          "filename",
          "clct_t",
          "change_req_checker",
          "label_v",
          "orig_dwl_t",
          "cncl_req_t",
          "cncl_cuz",
          "cncl_t",
        ],
        tab8: [
          "rework_cnt",
          "filename",
          "clct_t",
          "change_req_checker",
          "label_v",
          "status",
          "orig_dwl_t",
          "check_req_t",
          "cncl_req_t",
          "cncl_cuz",
          "cncl_t",
        ],
        tab10: [
          "rework_cnt",
          "filename",
          "clct_t",
          "change_req_checker",
          "label_v",
          "status",
          "orig_dwl_t",
          "gt_ul_t",
          "check_req_t",
          "ul_checker",
          "check_t",
          "payment_date",
          "check_date",

          "cncl_t",
        ],
        tab11: [
          "rework_cnt",
          "filename",
          "clct_t",
          "label_v",
          "status",
          "orig_dwl_t",
          "gt_ul_t",
          "check_req_t",
          "ul_checker",
          "check_t",
          "payment_date",
          "check_date",

          "cncl_req_t",
          "cncl_cuz",
          "cncl_t",
        ],
      },
    },
  },
};

export const makeImgTypeList = (sensor_code, gt_code) =>
  IMG_TYPE_LIST.filter((x) =>
    (sensor_code === "rad"
      ? ["orig_thumb", "map_thumb", "blend_thumb", "gt_thumb", "auto_thumb"]
      : gt_code === "det"
        ? ["orig_thumb", "blend_thumb", "auto_thumb"]
        : ["orig_thumb", "blend_thumb", "bbox_thumb", "gt_thumb", "auto_thumb"]
    ).includes(x.thumb)
  );
