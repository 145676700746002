import React, { useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import clsx from "clsx";
import { formatAmountComma } from "../../lib/utils";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { CONDITIONS, ORIGS, ROWS_PER_PAGE, SENSORS } from "../../lib/constants/constant";
import { SelectCheckbox, SelectItem } from "../atoms/SelectBox";
import { ImageSprite, StepIndicator } from "../atoms/CommonAtoms";
import { ErrorInspectButton } from "./actions";
import GetPreview from "./GetPreview";
import { workInspectDetailQuery } from "../../pages/work/Work.stores";
import { checkInspectDetailQuery } from "../../pages/check/Check.stores";

export const RenderRow = React.memo(
  ({
    row,
    index,
    columns,
    handleSelectTableRow,
    selected,
    previewQuery,
    tag,
    currentPage,
    currentMenu,
    linkPath,
    statusCodeData,
    groupColumnData,
    currentImgType,
    main,
  }) => {
    const linkRefs = useRef();

    const { linkTo } = useDynamicRouting();

    const renderLinkCol = (i, row, linkPath) => {
      const keys = ["path", "port", "berth", "vessel", "date"];
      let clct_name = "";

      keys.map((k) => (clct_name += row[k] && `${row[k]}${k !== "date" ? "_" : ""}`));

      return (
        linkPath && (
          <td key={i} align="center">
            <div
              ref={(el) => (linkRefs.current = el)}
              onClick={() => {
                linkTo({
                  // 메인 대시보드에서 들어올때 작업 가이드 탭 여부 확인
                  pathname: `${linkPath.path}/${row.id}/${clct_name}${main?.ref ? main.ref : ""}`,
                  // 메인 대시보드에서는 지정된 탭으로 이동
                  search: new URLSearchParams(
                    main?.tab
                      ? {
                          ...linkPath.query.data,
                          tab: main.tab,
                        }
                      : linkPath.query.data
                  ).toString(),
                });
              }}
            >
              <ImageSprite iconType="IC_link_b" />
            </div>
          </td>
        )
      );
    };

    let isShiftDown = false;
    useHotkeys("shift", () => (isShiftDown = true), { keydown: true });
    useHotkeys("shift", () => (isShiftDown = false), { keyup: true });

    return (
      <tr
        className="cursor-pointer hover:bg-gray100"
        onClick={() => handleSelectTableRow && handleSelectTableRow(row.id, isShiftDown)}
        onContextMenu={(e) => e.preventDefault()}
        onDoubleClick={() => linkRefs.current?.click() || null} // 더블클릭 데이터 리스트 이동
      >
        <td align="center">{index + 1 + (currentPage - 1) * ROWS_PER_PAGE}</td>
        {!main && (
          <td className="px-[8px] py-[4px]" align="center">
            <SelectItem>
              <SelectCheckbox checked={selected} tr />
            </SelectItem>
          </td>
        )}

        {previewQuery && (
          <td className="cursor-pointer" onClick={(e) => e.stopPropagation()}>
            <GetPreview
              previewQuery={previewQuery}
              index={index + (currentPage - 1) * ROWS_PER_PAGE}
              currentImgType={currentImgType}
              tag={tag}
            >
              <ImageSprite boxSize="scale_small" iconType="IC_preview_s" />
            </GetPreview>
          </td>
        )}

        {columns?.map((col, i) => {
          switch (col.type) {
            case "member":
              return RenderStrategies.member(i, row[col.name]);
            case "arr":
              return RenderStrategies.array(i, row[col.name]);
            case "filesize":
              return RenderStrategies.fileSize(i, row[col.name]);
            case "group":
              return RenderStrategies.group(
                col.name === "cond_cnts"
                  ? CONDITIONS
                  : col.name === "sensor_cnts"
                    ? SENSORS
                    : col.name === "img_cnts"
                      ? ORIGS
                      : [],
                row[col.name]
              );
            case "insp":
              return RenderStrategies.inspector(i, row, currentMenu.menu.en);
            case "insp_result":
              return RenderStrategies.emphasize(i, row[col.name]);
            case "num":
            case "strong":
              return RenderStrategies.number(i, row[col.name]);
            case "total":
              return RenderStrategies.total(i, row[col.name]);
            case "cnts":
              return RenderStrategies.group(
                groupColumnData,
                row[col.name],
                (col) => col.type === "strong"
              );
            case "code":
              return RenderStrategies.statusCode(i, row[col.name], statusCodeData);
            case "step":
              return RenderStrategies.step(i, row[col.name]);
            case "link":
              return renderLinkCol(i, row, linkPath, linkRefs.current);
            default:
              return RenderStrategies.common(i, row[col.name]);
          }
        })}
        <td></td>
      </tr>
    );
  }
);

const RenderStrategies = {
  emphasize(i, data, strong = false) {
    return (
      <td key={i}>
        <span className={clsx(data ? "" : "text-gray400", strong ? "font-bold text-primary" : "")}>
          {formatAmountComma(data)}
        </span>
      </td>
    );
  },

  group(group, data, strongPredicate = (col) => false) {
    return (
      group?.map((col, index) => {
        const cell = data?.[col.value] ? data[col.value] : 0;
        return this.emphasize(index, cell, strongPredicate(col));
      }) ?? []
    );
  },

  statusCode(i, data, statusCodes) {
    if (!statusCodes || !data)
      return (
        <td key={i} className="text-error">
          데이터 없음
        </td>
      );

    const statusCode = statusCodes.find((item) => item.value === `${data}`);
    const status = statusCode?.ko ?? `STATUS_CODE_${data}`;

    return <td key={i}>{status}</td>;
  },

  member(i, data) {
    return <td key={i}>{data ? data.display_name : "-"}</td>;
  },

  array(i, data) {
    return (
      <td key={i}>
        {data.length > 0
          ? data.map((item, index) => (index + 1 !== data.length ? `${item}, ` : item))
          : "-"}
      </td>
    );
  },

  fileSize(i, data) {
    return <td key={i}>{data}GB</td>;
  },

  inspector(i, data, menu) {
    return (
      <React.Fragment key={i}>
        {menu === "Work" && (
          <td
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {INSPECT_RESULTS.some((result) => data[result] > 0) ? (
              <ErrorInspectButton
                id={{ id: data.id }}
                listQuery={workInspectDetailQuery}
                actionName={`에러 검사 결과 (${data.filename})`}
                detail="레이어별 에러 타입과 에러 유무 검사 결과입니다."
              />
            ) : (
              "-"
            )}
          </td>
        )}
        {menu === "Check" && (
          <td
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {data.errs_exist ? (
              <ErrorInspectButton
                id={{ id: data.id }}
                listQuery={checkInspectDetailQuery}
                actionName={`에러 검사 결과 (${data.filename})`}
                detail="레이어별 에러 타입과 에러 유무 검사 결과입니다."
              />
            ) : (
              "-"
            )}
          </td>
        )}
      </React.Fragment>
    );
  },

  number(i, data) {
    return (
      <td key={i} align="right">
        {data === 0 ? "0" : formatAmountComma(data)}
      </td>
    );
  },

  total(i, data) {
    const obj = [
      {
        value: "eval",
        ko: "평가용 데이터",
      },
      {
        value: "val",
        ko: "학습용 데이터",
      },
      {
        value: "total",
        ko: "총합",
      },
    ];
    const temp = obj.find((x) => x.value === data)?.ko || data;
    return <td key={i}>{data ? temp : "-"}</td>;
  },

  step(i, data) {
    return <td key={i}>{data && data > 0 ? <StepIndicator step={data} /> : null}</td>;
  },

  common(i, data) {
    return (
      <td key={i}>
        {data === true ? "O" : data === false ? "X" : data === 0 ? "0" : data ? data : "-"}
      </td>
    );
  },
};

const INSPECT_RESULTS = [
  "empty_pixels",
  "obj_numbering_err",
  "layer_naming_err",
  "empty_layer",
  "labeling_err",
  "opacity_err",
  "invisible_layer",
  "group_err",
  "hidden_err",
  "duplicate_warn",
  "bg_err",
];
