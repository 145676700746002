import { IMG_TYPE_LIST } from "../../lib/constants/constant";

export const VLD_CLCT_COLS = {
  cam_seg: {
    tab: [
      "data",
      "path",
      "port",
      "berth",
      "vessel",
      "date",
      "transferred",
      "seg_auto_vld_options",
      "cam_seg_validating",
      "tab_cnts",
      "cam_seg_val_cnt",
      "cond_cnts",
      "cam_seg_pri",
      "cam_seg_vld_cmp_t",
      "clct_cmp_t",
      "module",
    ],
    tab1: [
      "data",
      "path",
      "port",
      "berth",
      "vessel",
      "date",
      "transferred",
      "seg_auto_vld_options",
      "tab_cnts",
      "cam_seg_val_cnt",
      "cond_cnts",
      "cam_seg_pri",
      "cam_seg_vld_cmp_t",
      "clct_cmp_t",
      "module",
    ],
    tab2: [
      "data",
      "path",
      "port",
      "berth",
      "vessel",
      "date",
      "transferred",
      "seg_auto_vld_options",
      "cam_seg_validating",
      "tab_cnts",
      "cond_cnts",
      "cam_seg_pri",
      "clct_cmp_t",
      "module",
    ],
    tab3: [
      "data",
      "path",
      "port",
      "berth",
      "vessel",
      "date",
      "transferred",
      "seg_auto_vld_options",
      "tab_cnts",
      "cam_seg_val_cnt",
      "cond_cnts",
      "cam_seg_pri",
      "cam_seg_vld_cmp_t",
      "clct_cmp_t",
      "module",
    ],
    hide: {
      tab2: ["module"],
      tab3: ["clct_cmp_t", "module"],
    },
  },
  cam_det: {
    tab: [
      "data",
      "path",
      "port",
      "berth",
      "vessel",
      "date",
      "transferred",
      "det_validating",
      "tab_cnts",
      "det_val_cnt",
      "cond_cnts",
      "det_pri",
      "det_vld_cmp_t",
      "clct_cmp_t",
      "module",
    ],
    tab1: [
      "data",
      "path",
      "port",
      "berth",
      "vessel",
      "date",
      "transferred",
      "tab_cnts",
      "det_val_cnt",
      "cond_cnts",
      "det_pri",
      "det_vld_cmp_t",
      "clct_cmp_t",
      "module",
    ],
    tab2: [
      "data",
      "path",
      "port",
      "berth",
      "vessel",
      "date",
      "transferred",
      "det_validating",
      "tab_cnts",
      "cond_cnts",
      "det_pri",
      "clct_cmp_t",
      "module",
    ],
    tab3: [
      "data",
      "path",
      "port",
      "berth",
      "vessel",
      "date",
      "transferred",
      "tab_cnts",
      "det_val_cnt",
      "cond_cnts",
      "det_pri",
      "det_vld_cmp_t",
      "clct_cmp_t",
      "module",
    ],
    hide: {
      tab2: ["module"],
      tab3: ["clct_cmp_t", "module"],
    },
  },
  rad_seg: {
    tab: [
      "data",
      "path",
      "port",
      "berth",
      "vessel",
      "date",
      "tab_cnts",
      "rad_seg_val_cnt",
      "rad_seg_pri",
      "rad_seg_vld_cmp_t",
      "clct_cmp_t",
      "module",
    ],
    tab1: [
      "data",
      "path",
      "port",
      "berth",
      "vessel",
      "date",
      "tab_cnts",
      "rad_seg_val_cnt",
      "rad_seg_pri",
      "rad_seg_vld_cmp_t",
      "clct_cmp_t",
      "module",
    ],
    tab2: [
      "data",
      "path",
      "port",
      "berth",
      "vessel",
      "date",
      "tab_cnts",
      "rad_seg_pri",
      "clct_cmp_t",
      "module",
    ],
    tab3: [
      "data",
      "path",
      "port",
      "berth",
      "vessel",
      "date",
      "tab_cnts",
      "rad_seg_val_cnt",
      "rad_seg_pri",
      "rad_seg_vld_cmp_t",
      "clct_cmp_t",
      "module",
    ],
    hide: {
      tab3: ["clct_cmp_t"],
    },
  },
};

export const VLD_DATA_COLS = {
  cam: {
    tab1: [
      "sensor_name",
      "clct_t",
      "filename",
      "transferred",
      "snow",
      "rain",
      "fog",
      "light",
      "backlight",
      "night",
      "smoke",
      "vld_type",
      "vld_t",
      "inf_mo",
      "vld_rep",
      "distributed",
    ],
    tab3: [
      "sensor_name",
      "clct_t",
      "filename",
      "transferred",
      "snow",
      "rain",
      "fog",
      "light",
      "backlight",
      "night",
      "smoke",
      "vld_type",
      "vld_t",
      "inf_mo",
    ],
    tab4: [
      "sensor_name",
      "clct_t",
      "filename",
      "transferred",
      "snow",
      "rain",
      "fog",
      "light",
      "backlight",
      "night",
      "smoke",
      "vld_type",
      "vld_t",
      "inf_mo",
      "vld_rep",
      "distributed",
    ],
    hide: {
      table: {
        tab1: [],
        tab3: [],
        tab4: [],
      },
      thumb: {
        tab1: ["filename"],
        tab3: ["filename"],
        tab4: ["filename"],
      },
    },
  },
  rad: {
    tab1: ["sensor_name", "clct_t", "filename", "map_ul_t", "vld_type", "vld_rep", "vld_t"],
    tab3: ["sensor_name", "clct_t", "filename", "map_ul_t", "vld_type", "vld_t"],
    tab4: ["sensor_name", "clct_t", "filename", "map_ul_t", "vld_type", "vld_rep", "vld_t"],
    hide: {
      table: {
        tab1: [],
        tab3: [],
        tab4: [],
      },
      thumb: {
        tab1: ["filename"],
        tab3: ["filename"],
        tab4: ["filename"],
      },
    },
  },
};

export const makeImgTypeList = (sensor_code) =>
  IMG_TYPE_LIST.filter((x) =>
    (sensor_code === "rad"
      ? ["orig_thumb", "map_thumb", "auto_thumb"]
      : ["orig_thumb", "inf_thumb", "auto_thumb"]
    ).includes(x.thumb)
  );

export const VLD_CAMSEG_TAB = [
  {
    ko: "전체",
    value: "1",
    name: "vld_cam_seg_tab1_cnt",
    col: "vldCamSegTab1Cnt",
  },
  {
    ko: "선별대기",
    value: "3",
    name: "vld_cam_seg_tab3_cnt",
    col: "vldCamSegTab3Cnt",
  },
  {
    ko: "선별완료",
    value: "4",
    name: "vld_cam_seg_tab4_cnt",
    col: "vldCamSegTab4Cnt",
  },
];

export const VLD_CAMDET_TAB = [
  {
    ko: "전체",
    value: "1",
    name: "vld_det_tab1_cnt",
    col: "vldDetTab1Cnt",
  },
  {
    ko: "선별대기",
    value: "3",
    name: "vld_det_tab3_cnt",
    col: "vldDetTab3Cnt",
  },
  {
    ko: "선별완료",
    value: "4",
    name: "vld_det_tab4_cnt",
    col: "vldDetTab4Cnt",
  },
];

export const VLD_RADSEG_TAB = [
  {
    ko: "전체",
    value: "1",
    name: "vld_rad_seg_tab1_cnt",
    col: "vldRadSegTab1Cnt",
  },
  {
    ko: "선별대기",
    value: "3",
    name: "vld_rad_seg_tab3_cnt",
    col: "vldRadSegTab3Cnt",
  },
  {
    ko: "선별완료",
    value: "4",
    name: "vld_rad_seg_tab4_cnt",
    col: "vldRadSegTab4Cnt",
  },
];

export const VLD_CAMSEG_CMP_TAB = [
  {
    ko: "전체",
    value: "1",
    name: "vld_cam_seg_tab1_cnt",
    col: "vldCamSegTab1Cnt",
  },
  {
    ko: "선별완료",
    value: "4",
    name: "vld_cam_seg_tab4_cnt",
    col: "vldCamSegTab4Cnt",
  },
];

export const VLD_CAMDET_CMP_TAB = [
  {
    ko: "전체",
    value: "1",
    name: "vld_det_tab1_cnt",
    col: "vldDetTab1Cnt",
  },
  {
    ko: "선별완료",
    value: "4",
    name: "vld_det_tab4_cnt",
    col: "vldDetTab4Cnt",
  },
];

export const VLD_RADSEG_CMP_TAB = [
  {
    ko: "전체",
    value: "1",
    name: "vld_rad_seg_tab1_cnt",
    col: "vldRadSegTab1Cnt",
  },
  {
    ko: "선별완료",
    value: "4",
    name: "vld_rad_seg_tab4_cnt",
    col: "vldRadSegTab4Cnt",
  },
];

export const VLD_TYPE_CODE = [
  { value: "1", ko: "선별 전" },
  { value: "2", ko: "대기" },
  { value: "3", ko: "평가용" },
  { value: "4", ko: "학습용" },
];
