import MainPage from "../pages/main/MainPage";
import ExtrClctPage from "../pages/extraction/ExtrClctPage";
import ExtrDataPage from "../pages/extraction/ExtrDataPage";
import RgsClctPage from "../pages/registration/RgsClctPage";
import RgsDataPage from "../pages/registration/RgsDataPage";
import HistClctPage from "../pages/history/HistClctPage";
import HistDataPage from "../pages/history/HistDataPage";
import CondClctPage from "../pages/condition/CondClctPage";
import CondDataPage from "../pages/condition/CondDataPage";
import VldClctPage from "../pages/validation/VldClctPage";
import VldDataPage from "../pages/validation/VldDataPage";
import WorkClctPage from "../pages/work/WorkClctPage";
import WorkDataPage from "../pages/work/WorkDataPage";
import WorkGuidePage from "../pages/work/WorkGuidePage";
import CheckClctPage from "../pages/check/CheckClctPage";
import CheckDataPage from "../pages/check/CheckDataPage";
import CheckGuidePage from "../pages/check/CheckGuidePage";
import LrnClctPage from "../pages/learning/LrnClctPage";
import LrnDataPage from "../pages/learning/LrnDataPage";
import StatsPage from "../pages/statistics/StatsPage";

export const PAGE_LIST = [
  // 나의 대시보드
  {
    path: "/main",
    component: MainPage,
  },
  // 데이터 추출
  {
    path: "/extraction",
    component: ExtrClctPage,
  },
  {
    path: "/extraction/:set_id/:set_name",
    component: ExtrDataPage,
  },
  // 데이터 등록
  {
    path: "/registration",
    component: RgsClctPage,
  },
  {
    path: "/registration/:set_id/:set_name",
    component: RgsDataPage,
  },
  // 수집환경 입력
  {
    path: "/condition/:sensor_code",
    component: CondClctPage,
  },
  {
    path: "/condition/:sensor_code/:set_id/:set_name",
    component: CondDataPage,
  },
  // 데이터 삭제 내역
  {
    path: "/history/:sensor_code",
    component: HistClctPage,
  },
  {
    path: "/history/:sensor_code/:set_id/:set_name",
    component: HistDataPage,
  },
  // 학습 데이터 선별
  {
    path: "/validation/:sensor_code/:gt_code",
    component: VldClctPage,
  },
  {
    path: "/validation/:sensor_code/:gt_code/:set_id/:set_name",
    component: VldDataPage,
  },
  // 데이터 작업
  {
    path: "/work/:sensor_code/:gt_code",
    component: WorkClctPage,
  },
  {
    path: "/work/:sensor_code/:gt_code/:set_id/:set_name",
    component: WorkDataPage,
  },
  {
    path: "/work/:sensor_code/:gt_code/:set_id/:set_name/ref",
    component: WorkGuidePage,
  },
  // 데이터 검수
  {
    path: "/check/:sensor_code/:gt_code",
    component: CheckClctPage,
  },
  {
    path: "/check/:sensor_code/:gt_code/:set_id/:set_name",
    component: CheckDataPage,
  },
  {
    path: "/check/:sensor_code/:gt_code/:set_id/:set_name/ref",
    component: CheckGuidePage,
  },
  // 학습 데이터 관리
  {
    path: "/learning/:sensor_code/:gt_code",
    component: LrnClctPage,
  },
  {
    path: "/learning/:sensor_code/:gt_code/:set_id/:set_name",
    component: LrnDataPage,
  },
  // 통계 대시보드
  {
    path: "/statistics",
    component: StatsPage,
  },
];
