import { useReducer } from "react";

const updateReducer = (number) => (number + 1) % 1_000_000;

const useUpdate = () => {
  const [token, update] = useReducer(updateReducer, 0);
  return [token, update];
};

export default useUpdate;
