import React, { createContext, useMemo, useReducer } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { browserName, isMobile, isTablet } from "react-device-detect";
import { RecoilRoot } from "recoil";
import Section from "./components/templates/Section";
import InvalidPage from "./components/templates/InvalidPage";
import { DynamicRoutingProvider } from "./lib/context/useDynamicRouting";
import { RootErrorBoundary } from "./components/templates/RootErrorBoundary";
import { initializeStorage } from "./lib/storage";

export const NAVLUEContext = createContext();

export const initialState = { progressRows: [] };

export const NAVLUEContextReducer = (state, action) => {
  const { type, data } = action;

  if (type === "progressRows") return { ...state, progressRows: data };

  return state;
};

// 배포 환경에서 console.log, console.warn 지우기
if (process.env.NODE_ENV === "production") {
  console.log = function no_console() {};
  console.warn = function no_console() {};
}

const App = () => {
  const [state, dispatch] = useReducer(NAVLUEContextReducer, initialState);
  const { progressRows } = state;
  const NAVLUEContextValue = useMemo(() => ({ progressRows, dispatch }), [progressRows]);

  initializeStorage("sort", ["menu", "sensor_code", "gt_code", "level", "isGuide", "tab"]);
  initializeStorage("column", ["menu", "sensor_code", "gt_code", "level", "isGuide", "tab"]);
  initializeStorage("filter", ["menu", "sensor_code", "gt_code", "level", "isGuide", "set_id"]);

  // PC Chrome에서만 사용 가능
  if (browserName !== "Chrome" || isMobile || isTablet) return <InvalidPage />;

  return (
    <NAVLUEContext.Provider value={NAVLUEContextValue}>
      <RecoilRoot>
        <RootErrorBoundary>
          <Router>
            <DynamicRoutingProvider>
              <Section />
            </DynamicRoutingProvider>
          </Router>
        </RootErrorBoundary>
      </RecoilRoot>
    </NAVLUEContext.Provider>
  );
};

export default App;
